import React, { Component } from 'react';
import styles from './Topics.module.css';
import Topic from './Topic/Topic';
import { connect } from 'react-redux';
class Topics extends Component {
    

    render(){
        let topics = (
            <>
            <div className={styles.Loading}></div>
            <div className={styles.Loading}></div>
            <div className={styles.Loading}></div>
            <div className={styles.Loading}></div>
            <div className={styles.Loading}></div>
            <div className={styles.Loading}></div>
            </>
        );
        if (this.props.category) {
            topics = (
                this.props.category.map((topic)=> {
                    return <Topic key={topic.id} title={topic.name} src={topic.image} path={topic.path} />
                })
            );
        }
        return (
            <div className={styles.TopicsContainer}>
            <h1>درس‌ها</h1>
            <div className={styles.Topics}>
                {topics}
            </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        category : state.category
    }
}
export default connect(mapStateToProps)(Topics);