import React from 'react';
import styles from './LazyLoadingFallback.module.css';
import Spinner from '../Spinner/Spinner';

const LazyLoadingFallback = (props)=>{
    return (
        <div className={styles.LazyLoadingFallback}>
        <div className={styles.Loading}>
        <Spinner />
        </div>
        <h1 className={styles.Text}>در حال بارگذاری ... </h1>
        </div>
        
    )
}
export default LazyLoadingFallback;