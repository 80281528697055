import styles from "./MultipleChoice.module.css";
import Choice from "./Choice/Choice";

const MultipleChoice = (props) => {
  let choices = (
    <>
      <Choice loading />
      <div className={styles.Line}></div>
      <Choice loading />
      <div className={styles.Line}></div>
      <Choice loading />
      <div className={styles.Line}></div>
      <Choice loading />
    </>
  );
  if (props.choices) {
    choices = props.choices.map((choice, index) => {
      return (
        <>
          <Choice
            id={props.id}
            isChecked={props.isChecked}
            quastion={choice}
            value={`${index + 1}`}
            name={props.name}
            onSubmit={props.onSubmit}
            answer={props.answer}
          />
          <div className={styles.Line}></div>
        </>
      );
    });
  }

  return (
    <div
      className={[
        styles.MultipleChoice,
        props.answer === true
          ? props.isChecked
            ? styles.Right
            : null
          : props.isChecked
          ? styles.Wrong
          : null,
      ].join(" ")}
    >
      <h2
        className={[
            styles.Title, 
            props.title ? null : styles.Loading].join(
          " "
        )}
      >
        {props.title}
      </h2>
      <div className={styles.Content}>
        <form className={styles.Choices}>
          <div className={styles.Line}></div>
          {choices}
        </form>
        {props.image ? <img src={props.image} alt={props.title} /> : null}
      </div>
    </div>
  );
};
export default MultipleChoice;
