import React, { Component } from "react";
import styles from "./AppsButton.module.css";
import { connect } from "react-redux";
import AppsList from "./AppsList/AppsList";
class AppsButton extends Component {
  state = {
    showList: false,
    notifications: [],
  };
  componentDidMount() {
    document.addEventListener("click", (event) => {
      if (this.Notifications && this.state.showList) {
        if (!this.Notifications.contains(event.target)) {
          this.setState({ showList: false });
          // console.log(this.Notifications)
        }
      }
    });
  }
  ToggleList = () => {
    this.setState((prevState) => {
      return {
        showList: !prevState.showList,
      };
    });
  };
  CloseList = () => {
    this.setState({ showList: false });
  };
  render() {
    return (
      <>
        {this.props.token ? (
          <>
            <div
              ref={(el) => {
                this.Notifications = el;
              }}
              onClick={this.ToggleList}
              className={[
                styles.AppsButton,
                this.props.white ? styles.White : null,
              ].join(" ")}
            >
              {this.state.notifications.length > 0 ? (
                <span className={styles.NotificationCount}>
                  {"1".toPersinaDigit()}
                </span>
              ) : null}
              <div className={styles.Icon}>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
              </div>
              {this.state.showList ? (
                <AppsList
                  Notifications={this.state.notifications}
                  CloseList={this.CloseList}
                />
              ) : null}
            </div>
          </>
        ) : null}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};
export default connect(mapStateToProps)(AppsButton);
