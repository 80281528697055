import React, { Component} from 'react';
import styles from './Comments.module.css';
import Avatar from '../Avatar/Avatar';
import Comment from './Comment/Comment';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import * as actions from '../../store/actions/action';
import Emoji from '../Emoji/Emoji';
import EmptyBox from '../EmptyBox/EmptyBox';
class Comments extends Component {
    
    state = {
        NewComment : '',
        showCommentsCount : 5,
        
    }
    TextAreaResize = (event)=>{
        
        const element = event.target ;
        element.style.height = "auto";
        element.style.height = (1 +element.scrollHeight)+"px";
    }
    NewComment = (event)=> {
   
        this.setState({NewComment : event.target.value});
    }
    SendConmmet = ()=>{
        this.props.SendComment(this.state.NewComment);
        this.setState({NewComment : '' });
        
    }

    ShowMore = ()=>{
        this.setState((prevState)=>{
            return {
                showCommentsCount : prevState.showCommentsCount + 5
            }
        })
    }
    AddEmojiHandler = (emoji)=>{
        this.setState((prevState)=>{
            return {
                NewComment : prevState.NewComment + emoji.native
            }
        })
    }
    render() {
        
        let comments = null;
        let commentsCount = 0;
        
        if (this.props.Comments && this.props.Comments.length > 0) {
            commentsCount = this.props.Comments.length;
            const commentArray = this.props.Comments;
            comments = ( commentArray.map((comment , index)=>{
                // console.log(comment)
                if (index > this.state.showCommentsCount) {
                    return null;
                }
                let isLiked = false;
                if (comment.activity) {
                    comment.activity.forEach((a)=>{
                        if (a === 'LI') {
                            isLiked = true
                        }
                        else {
                            isLiked = false;
                        }
                    })
                }
                
                // console.log(isLiked)
                return (
                    <div className={styles.Comments} key={comment.id ? comment.id : Math.random() * 50}>
                    <Comment
                    LogedIn={this.props.token ? true : false} 
                    Image={comment.author ? comment.author.avatar : ''} 
                    Name={comment.author ? comment.author.name : ''}
                    LikeCounts={comment.likes ? comment.likes : 0}
                    Comment={comment.text}
                    CreatedAt = {comment.created_at}
                    Token = {this.props.token}
                    Liked = {isLiked}
                    CommentId={comment.id}/>
                    {index === this.props.Comments.length - 1 ? null : <div className={styles.Line}></div>}
                    </div>
                )
            }))
        }
        return (
            <div className={styles.Comments}>
                <h1 className={styles.Title}>{commentsCount.toString().toPersinaDigit()} نظر</h1>
                {this.props.token ? <div className={styles.NewComment}>
                <div className={styles.Avatar}>
                <Avatar />
                </div>
                
                <textarea 
                onInput = {this.TextAreaResize}
                value={this.state.NewComment}
                placeholder="نظر خود را بنویسید ..." 
                onChange={this.NewComment}
                onKeyDown={(event)=>{
                    // if (event.keyCode === 13) {
                    //     this.SendConmmet();
                    // }
                }}/>
                <div className={styles.ButtonsBar}>
                <div className={styles.EmojiContainer}>
                <Emoji AddEmojiHandler={(emoji)=>{this.AddEmojiHandler(emoji)}} />
                </div>
                <div className={styles.AddNewComment} onClick={this.SendConmmet}> 
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve">
<g>
	<g>
		<path d="M481.508,210.336L68.414,38.926c-17.403-7.222-37.064-4.045-51.309,8.287C2.86,59.547-3.098,78.551,1.558,96.808    L38.327,241h180.026c8.284,0,15.001,6.716,15.001,15.001c0,8.284-6.716,15.001-15.001,15.001H38.327L1.558,415.193    c-4.656,18.258,1.301,37.262,15.547,49.595c14.274,12.357,33.937,15.495,51.31,8.287l413.094-171.409    C500.317,293.862,512,276.364,512,256.001C512,235.638,500.317,218.139,481.508,210.336z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
                </svg>
                </div>
                 </div>
                
                

                </div> : 
                <div className={styles.SignInToComment}>
                <span> برای ثبت نظر لطفا ابتدا<Link to="/Login/SignIn" onClick={()=>{
                    this.props.SetRedirectPath(this.props.location.pathname)
                }}> وارد شوید.</Link></span>
                </div>}

                <div className={styles.CommentsContainer}>
                    {
                       comments ? comments : 
                       <div className={styles.EmptyBox }> 
                       <EmptyBox /> 
                       <span>کامنتی وجود ندارد!</span>
                       </div>
                    }
                </div>
                {commentsCount > this.state.showCommentsCount ?
                <div
                className={styles.ShowMoreButton} 
                onClick={this.ShowMore}>
                    <span>نمایش نظرات بیشتر</span>
                    <div className={styles.Arrow}>
                    <div className={styles.RightArrow}></div>
                    <div className={styles.LeftArrow}></div>
                    </div>
            </div> : null}
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        token : state.token
    }
}
const mapDispatchToProps = dispatch =>{
    return {
        SetRedirectPath : (path)=> dispatch(actions.SetRedirectPath(path))
    }
}
export default connect(mapStateToProps , mapDispatchToProps)(withRouter(Comments));