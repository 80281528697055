import React from 'react'; 
import styles from './ListItem.module.css';
const listItem = (props)=> {
    const classNames = [styles.ListItemContainer , props.active ? styles.Active : null ,  props.isBackButton ? styles.BackButton : null]
    return (
        <div className={classNames.join(' ')} onMouseEnter={props.mouseEnter} onClick={props.click} >
         <li className={styles.ListItem}  onMouseLeave={props.mouseLeave}>{props.children}</li>
         {props.hasIcon ?  <div className={styles.Arrow}>
                <div className={styles.RightArrow}></div>
                <div className={styles.LeftArrow}></div>
            </div> :  null}
         </div>)
}
export default listItem;