import React, { Component } from 'react';
import { connect } from 'react-redux';
import CourseTopic from '../../Components/CourseTopic/CourseTopic';
import styles from './Topic.module.css';
import {Helmet} from 'react-helmet';
import CourseTopicSkeleton from '../../Components/CourseTopic/CourseTopicSkeleton/CourseTopicSkeleton';
import Subject from '../../Components/Subject/Subject';
import CourseGridContainer from '../../Components/GridContainers/CourseGridContainer/CourseGridContainer';
import TopicSearch from '../../Components/TopicSearch/TopicSearch';


class Topic extends Component {
    state = {
        SubTopics :null,
        searchValue : ''
    }
    componentDidMount(){
      
        window.scrollTo({top: 0 , left:0 , behavior:'auto'});
    
    }
    render() {
        
        let topic = null;
        if (topic === null && this.props.category) {
            topic =  this.props.category.filter((topic)=> {
                if(topic.path.toString() === this.props.match.params.TopicName.toString()){  
                    return topic;
                }
                else {
                    return null;
                }
            })
            
        }




        
        let SubTopics = <CourseTopicSkeleton />
        let subject = <Subject Loading />
        let TopicImageUrl = '';
        let TopicName = '';
        if(topic){
            if(!topic[0]) {
                this.props.history.push('/NotFound')
            }
            else {
                TopicImageUrl = topic[0].image;
                TopicName = topic[0].name;
                subject = <Subject Image={TopicImageUrl} Name={TopicName} />
                 SubTopics =  topic[0].childs
            .filter(SubTopic => 
                SubTopic.name.toLowerCase()
                .includes(this.state.searchValue.toLowerCase()))
                .map((SubTopic)=>{
                    // console.log(SubTopic)
                return (
                    <CourseTopic
                    Path = { '/Topic/' + this.props.match.params.TopicName + '/' + SubTopic.path}
                    Src={SubTopic.image} 
                    Title={SubTopic.name} 

                    CourseCount={SubTopic.parts ? SubTopic.parts.toString() : '0'}
                    CourseTime = {SubTopic.time ? SubTopic.time.toString() : '0'}
                    key={SubTopic.id} />
                )
            }) 
            }
           
        }
        
        return (
            <>
            <Helmet>
            <title>براکت آکادمی - {TopicName.toString()}</title>
            <meta name="description" content={"براکت آکادمی - " + TopicName.toString()} />
            <meta name="title" content={"براکت آکادمی" - TopicName.toString()} />
            <meta property="og:type" content="topic"/>
            <meta property="og:title" content={"براکت آکادمی" - TopicName.toString()}/>
            <meta property="og:description" content={"براکت آکادمی" - TopicName.toString()}/>
            {/* Image Meta Tags */}
            <meta property="og:image" content={TopicImageUrl}/>
            <meta property="og:image:secure_url" content={TopicImageUrl}/>
            <meta property="og:image:width" content="256" />
            <meta property="og:image:height" content="256" />
            <meta property="og:image:type" content="image/png" />
            </Helmet>
            <div className={styles.TopicContainer}>
            {subject}

          
            <TopicSearch onChange={(event)=>{
                this.setState({searchValue : event.target.value})
            }} searchValue={this.state.searchValue} />
            
            <CourseGridContainer className={styles.Topic}>
            {
             SubTopics
            }
            </CourseGridContainer>
            </div>
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        category : state.category
    }
}
export default connect(mapStateToProps)(Topic);