import React, { Component } from 'react';
import Backdrop from '../../Components/Backdrop/Backdrop';
import Footer from '../../Components/Footer/Footer';
import Navigation from '../../Components/Navigation/Navigation';
import SideDrawer from '../../Components/SideDrawer/SideDrawer';
import {connect} from 'react-redux';
import ErrorMessage from '../../Components/Message/ErrorMessage/ErrorMessage';
import Loading from '../../Components/Loading/Loading';
import * as actions from '../../store/actions/action';
import styles from './Layout.module.css';
import GoUpButton from '../../Components/Buttons/GoUpButton/GoUpButton';
import { withRouter } from 'react-router';
class Layout extends Component {
    state = {
        showGoUpButton : false,
    }
    componentDidMount(){
        window.addEventListener('resize' , ()=>{
            if (window.innerWidth > 900 && this.props.sideDrawerOpen) {
                this.props.onCloseSideDrawer();
            }
          })
    }
    OnScroll = (event) => {
        if(window.scrollY > 200 && !this.state.showGoUpButton) {
            this.setState({showGoUpButton : true});
            // console.log(this.state.showGoUpButton)
        }
        if(window.scrollY <= 200 && this.state.showGoUpButton) {
            this.setState({showGoUpButton : false})
        }
    }
   
    render(){
        // console.log(this.props.location.pathname.split('/'))
        document.addEventListener('scroll' , this.OnScroll)
        return (
            <div className={styles.Layout} >
            <Backdrop />
            <Loading />
            <Navigation isHome={this.props.location.pathname.split('/')[1] === 'Home' ? true : false} />
            <SideDrawer  /> 
            {this.props.children}
            <ErrorMessage />
            {this.props.location.pathname.split('/')[1] !== 'Content'?<Footer /> : null}
            {this.state.showGoUpButton ? <GoUpButton /> : null}
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCloseSideDrawer : ()=> dispatch(actions.CloseSideDrawer()),
    }
}
const mapStateToProps = state => {
    return {
        sideDrawerOpen : state.sideDrawerOpen
    }
}
export default connect(mapStateToProps , mapDispatchToProps)(withRouter(Layout));