import { Link } from 'react-router-dom';
import styles from './Tag.module.css';
const Tag = (props)=>{
    console.log()
    return (
        <div className={[styles.TagContainer , props.loading ? styles.Loading : null].join(' ')}>
        {props.loading ?? <Link className={styles.Tag} to={"/Search?SearchValue=" + props.children}>#{props.children}</Link>}
        </div>
    )
}

export default Tag;