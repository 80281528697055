import React from 'react';
import styles from './CourseTopicSkeleton.module.css';
const courseTopicSkeleton = (props) => {

    return (
            <>
            <div className={styles.Loading}></div>
            <div className={styles.Loading}></div>
            <div className={styles.Loading}></div>
            <div className={styles.Loading}></div>
            <div className={styles.Loading}></div>
            <div className={styles.Loading}></div>
            <div className={styles.Loading}></div>
            <div className={styles.Loading}></div>
            <div className={styles.Loading}></div>
            <div className={styles.Loading}></div>
            <div className={styles.Loading}></div>
            <div className={styles.Loading}></div>
           
            </>
    )
}

export default courseTopicSkeleton;