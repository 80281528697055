import styles from './Congratulations.module.css';
const Congratulations =(props)=>{
    const papers = [];
    const number = 200;
    for (let i = 0; i < number; i++) {
        const el = <div 
        className={styles.Paper}
        style={{
            animationDelay : Math.random() * 2 + 's',
            left : 100 / number * i + 'vw',
            backgroundColor : 'rgb(' +  Math.random() * 255 
            + ',' +  Math.random() * 255 + ',' +  Math.random() * 255 + ')',
            transform : 'rotateZ(' + Math.random() * 360 + 'deg)'
        }}></div>
        papers.push(el)
        
    }
    return (
        <div className={styles.Congratulations}>
            {papers}
        </div>
    )
}
export default Congratulations;