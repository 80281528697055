import React , {Component} from 'react';
import videojs from 'video.js';

import  './VideoPlayer.css';
import 'video.js/dist/video-js.css';
import 'videojs-seek-buttons'

//Ads Suppport
import 'videojs-ima';
import 'videojs-ima/dist/videojs.ima.css';
import 'videojs-contrib-ads';

// Multiple Quality
import 'videojs-contrib-quality-levels';
import 'videojs-hls-quality-selector';
class VideoPlayer extends Component {
    state = {
        isViewed : false
    }
    componentDidMount(){
        const options = {
            adTagUrl: 'http://napi.arvancloud.com/vads/campaigns/57fda0b8-d4a8-44cd-9b99-cbac70ec87ab/vast',
            adLabel : ' تبلیغات ',
            
            
        }
        this.player = videojs(this.videoNode , this.props , function onPlayerReady() {})
        // this.player.ima(options)
        this.player.qualityLevels();
        this.player.hlsQualitySelector({
            displayCurrentQuality: false,
            vjsIconClass : 'vjs-icon-cog'});
        
        
       
      
         
    }
    componentWillUnmount(){
        if (this.player) {
            this.player.dispose();
        }
    }
    render(){

        return (
            <>
            <div 
            onClick = {
                ()=>{
                    if (this.videoNode && !this.state.isViewed) {
                        if (this.videoNode.played && this.props.AddView) {
                            this.setState({isViewed : true});
                            this.props.AddView();
                            // console.log('View Added')
                           
                        }
                    } 
                }
            }
            style={{width : '100% ' , height: '100%'}} data-vsj-player>
                <video 
                
                ref={(node) => {
                    this.videoNode = node;
                    if (node) {
                        node.addEventListener('click' , ()=>{
                            if (this.videoNode && !this.state.isViewed) {
                                if (this.videoNode.played && this.props.AddView) {
                                    this.setState({isViewed : true});
                                   
                                    this.props.AddView();
                                   
                                }
                            } 
                        } )
                    }
                   
                }} 
                className="video-js vjs-big-play-centered"></video>
            </div>
                </>
        )
    }
}

export default VideoPlayer;