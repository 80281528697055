import React, { Component } from 'react';
import './HTMLParser.css';
class HTMLParser extends Component {
    state = {
        HTML : [
            {
                type : 'Heading1',
                content : 'هندینگ 1',
            },
            {
                type : 'Paragraph',
                content : 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.',
                
            
            },
            {
                type : 'Image',
                src : 'https://lh3.googleusercontent.com/proxy/ade_liaqN7H3KLUHpmMGd16xDZIyDZynv_eWmYyfJTw7Pdm3wuOA3D5BbSySUKMSWwoJ7O6_6b0l7p_SFGbOIXKtQB1eMVK3J_kiZus0M4K2nM9aI2ibo_4ZJjVdo1DquOuaXRgyDhIz76NhxLbBEIps',
                alt : 'DummyImage'
            },
            {
                type : 'Heading2',
                content : 'هدینگ 2'
            },
            {
                type : 'Paragraph',
                content : 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.'
            },
            {
                type : 'Heading2',
                content : 'هدینگ 2'
            },
            {
                type : 'Paragraph',
                content : 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.'
            },
            {
                type : 'Image',
                src : 'https://lh3.googleusercontent.com/proxy/ade_liaqN7H3KLUHpmMGd16xDZIyDZynv_eWmYyfJTw7Pdm3wuOA3D5BbSySUKMSWwoJ7O6_6b0l7p_SFGbOIXKtQB1eMVK3J_kiZus0M4K2nM9aI2ibo_4ZJjVdo1DquOuaXRgyDhIz76NhxLbBEIps',
                alt : 'DummyImage'
            },
        ]
    }
    render() {
        const ParsedHTML = this.state.HTML.map(HTMLElement => {
            switch (HTMLElement.type) {
                case  'Heading1' :
                    return <h1 className="Heading1">{HTMLElement.content}</h1>
                case 'Heading2':
                    return <h2 className="Heading2">{HTMLElement.content}</h2>
                case 'Paragraph':
                    return <p className="Paragraph">{HTMLElement.content}</p>
                case 'Image' :
                    return <img className="Image" alt={HTMLElement.alt} src={HTMLElement.src} />
                default :
                    return null;
            }
        })
        return (
            <div>
                { ParsedHTML}
            </div>
        )
    }
}

export default HTMLParser;