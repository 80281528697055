import React from 'react'
import styles from './TopicSearch.module.css'
const TopicSearch = (props)=>{
    return (
        <div className={styles.InputsContainer}>
            <div className={styles.SearchContainer}>
            <input 
            className={styles.Search} 
            type="text" 
            value={props.searchValue} 
            onChange={props.onChange}
            placeholder="جست و جو" />
             <svg onClick={()=>{
                // console.log('searching ...')
                
            }} className={styles.Icon} id="Search" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <rect id="Rectangle_693" data-name="Rectangle 693" width="16" height="16" fill="none"/>
            <path id="Path_208" data-name="Path 208" d="M15.9,14.5l-3.3-3.3A6.847,6.847,0,0,0,14,7,6.957,6.957,0,0,0,7,0,6.957,6.957,0,0,0,0,7a6.957,6.957,0,0,0,7,7,6.847,6.847,0,0,0,4.2-1.4l3.3,3.3ZM2,7A4.951,4.951,0,0,1,7,2a4.951,4.951,0,0,1,5,5,4.951,4.951,0,0,1-5,5A4.951,4.951,0,0,1,2,7Z"/>
            </svg>
            </div>
            
            </div>
    )
}

export default TopicSearch;