import React from 'react';
import styles from './ArticleItemSkeleton.module.css';
const ArticleItemSkeleton = (props)=>{
    let skeletons = [];
    for (let index = 0; index < 20; index++) {
         skeletons = [...skeletons , index]
        
    }
    return (
        <>
        {skeletons.map((el)=> 
        <div className={[styles.ArticleItemSkeleton , 
        styles.Loading].join(' ')}></div>)}
        </>
    )
}
export default ArticleItemSkeleton;