import React from 'react';
import styles from './HamMenu.module.css';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/action';
const hamMenu = (props)=>{
    return (
        <div onClick={props.onToggleSideDrawer} className={[styles.HamMenu , 'HamMenu' , props.white ? styles.White : null].join(' ')}>
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}
const mapDispatchToProps = dispatch => {
    return {
        onToggleSideDrawer : ()=> dispatch(actions.ToggleSideDrawer()),
    }
}
export default connect(null ,mapDispatchToProps )(hamMenu);