import styles from './Button.module.css';
const Button = ({
  children,
  disabled,
  onClick,
  onKeyDown,
  className,
  style,
  type
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      onKeyDown={onKeyDown}
      className={[className, styles.Button ,type ? styles[type] : 'primary'].join(" ")}
      style={style}
    >
      {children}
    </button>
  );
};
export default Button;
