import React  from 'react';
import Courses from '../Courses/Courses';
import LoginButton from '../Buttons/LoginButton/LoginButton';
import Logo from '../Logo/Logo';
import Search from '../Search/Search';
import HamMenu from '../HamMenu/HamMenu';
import styles from './Navigation.module.css';
import AccountButton from '../AccountButton/AccountButton';
import './Navigation.css';
import { connect } from 'react-redux';
import NotificationButton from '../Buttons/NotificationButton/NotificationButton';
import { Component } from 'react';
import AppsButton from '../Buttons/AppsButton/AppsButton';
class Navigation extends Component {
    state = {
        isStandardNavigation : true
    }
    
    componentDidMount() {
        if (this.props.isHome) {
            this.setState({isStandardNavigation : false})
            
        }
        window.addEventListener('scroll', ()=>{
            if (window.scrollY >= 200) {
                if (this.state.isStandardNavigation === false) {
                    this.setState({isStandardNavigation : true})
                }
            }
            else if (this.props.isHome) {
                if (this.state.isStandardNavigation === true) {
                this.setState({isStandardNavigation : false})
                }
            }
        })
    }
    componentDidUpdate(prevProps){
        if (this.state.isStandardNavigation=== false && this.props.isHome === false) {
            if (this.state.isStandardNavigation === false) {
            this.setState({isStandardNavigation : true})
            }
        }
        if (prevProps.isHome !== this.props.isHome) {
            if (this.props.isHome) {
                if (this.state.isStandardNavigation === true) {
                this.setState({isStandardNavigation : false})
                }
            }
        }
    }
    render()
    {
        console.log('Navigation | Rerender');
        return (
        <header className={[styles.Navigation , this.state.isStandardNavigation ? null : styles.HomeNavigation].join(' ')} >
            <div className={styles.RightSide}>
            <HamMenu white={!this.state.isStandardNavigation} />
            <Courses white={!this.state.isStandardNavigation} />
            {this.state.isStandardNavigation ? <Search /> : false}
            </div>
            
            <Logo 
            white={!this.state.isStandardNavigation} 
            sideDrawerOpen={this.props.sideDrawerOpen} />
           
            <div className={styles.LeftSide}>
            <AppsButton white={!this.state.isStandardNavigation} />
            <LoginButton  white={!this.state.isStandardNavigation} />
            <NotificationButton white={!this.state.isStandardNavigation} />
            <AccountButton white={!this.state.isStandardNavigation} />
            </div>
        </header>
    )}
}
const mapStateToProps = state => {
    return {
        sideDrawerOpen : state.sideDrawerOpen,
    }
}
export default connect(mapStateToProps)(Navigation);