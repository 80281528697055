import React, { Component } from 'react';
import styles from './Courses.module.css';
import List from './List/List';
class Courses extends Component {
    constructor(){
        super();
        this.Courses = null;
    }
state = {
    showList : false,
    
}
    ShowList = (event)=> {
        this.setState({showList: true});
    }
    
    HideAllLists = (event)=>{
        this.setState({showList: false})
    }
    ToggleList = (event)=>{
        this.setState((preState)=>{
            return {
                showList : !preState.showList
            }
        })
    }
    componentDidMount(){
        document.addEventListener('click' , (event)=>{
            if (this.Courses && this.state.showList) {
                if (!this.Courses.contains(event.target)) {
                    this.setState({showList : false})
                    // console.log(this.accountButton)
                } 
            }
            
        });
        document.addEventListener('resize' , ()=>{
            if (window.innerWidth < 900 && !this.state.showList) {
                this.setState({showList : true})
                

            }
            if(window.innerWidth >= 900) {
                this.setState({showList : false})
                
            }
        } )
 
    }
    render(){
      
      
       let list = null;
       if(this.state.showList || window.innerWidth < 900){
           list = <List 
           closeSideDrawer={this.props.closeSideDrawer}
           CloseList={this.HideAllLists} />
       }
        return(
            <div className="courses" ref={(el)=>{this.Courses = el}}>
            <div className={[styles.Courses ,
             this.state.showList ? styles.Active : null , this.props.white ? styles.White : null].join(' ')}
            //  onMouseEnter={this.ShowList}
              onClick={this.ToggleList}>
                
            <span>درس‌ها</span>
            <div className={styles.Arrow}>
                <div className={styles.RightArrow}></div>
                <div className={styles.LeftArrow}></div>
            </div>
            </div>
            <div onMouseLeave={this.HideAllLists} className={styles.ListContainer}>
            {list}
           
            </div>
            </div>

        )
    }
}
export default Courses;