import React from 'react';
import { withRouter } from 'react-router';
import styles from './Search.module.css';
class search  extends React.Component {
    state = {
        classes : [styles.SearchContainer],
        SearchValue : ''
    }
    SearchActive = ()=>{
        if (window.innerWidth < 900) {
            
            this.setState({classes : [styles.SearchContainer , styles.SearchActive],
            SearchValue : ''})
        }
    }
    SearchDeActive = ()=>{
        this.setState({classes
             : [styles.SearchContainer , styles.SearchDeActive],
             SearchValue : ''})
        
    }
    Searching = ()=>{
        if (this.state.SearchValue.trim() !== '') {
            this.props.history.push('/Search?SearchValue=' + this.state.SearchValue)
            this.setState({SearchValue : ''})
        }
    }
    render(){
   
    return (
        <div  className={this.state.classes.join(' ')} onKeyDown={(event)=>{
            if (event.key === 'Enter') {
                this.Searching();
                this.SearchDeActive();
            }
        }}>
            <div className={styles.Close} onClick={this.SearchDeActive}>
            <svg   xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 329.26933 329" ><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/></svg>
            </div>
            <input  
            className={styles.Search} 
            value={this.state.SearchValue} 
            onChange={(e)=>{
                this.setState({SearchValue : e.target.value});
            }} 
            type="search" 
            placeholder="جست و جو " />
            <svg onClick={()=>{
                this.SearchActive();
                this.Searching();
            }} className={styles.Icon} id="Search" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <rect id="Rectangle_693" data-name="Rectangle 693" width="16" height="16" fill="none"/>
            <path id="Path_208" data-name="Path 208" d="M15.9,14.5l-3.3-3.3A6.847,6.847,0,0,0,14,7,6.957,6.957,0,0,0,7,0,6.957,6.957,0,0,0,0,7a6.957,6.957,0,0,0,7,7,6.847,6.847,0,0,0,4.2-1.4l3.3,3.3ZM2,7A4.951,4.951,0,0,1,7,2a4.951,4.951,0,0,1,5,5,4.951,4.951,0,0,1-5,5A4.951,4.951,0,0,1,2,7Z"/>
            </svg>

        
        </div>
    )}
}

export default withRouter(search);