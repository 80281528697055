import React, { Component } from 'react';
import ListItem from './ListItem/ListItem';
import {Link} from 'react-router-dom';
import styles from './List.module.css';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/action';
class List extends Component {
   
    state = {
        childItems : null,
        listClassNames : [styles.List],
        childListClassNames : [styles.ChildList],
        indexOfActiveListItem : null
    }

ListItemMouseEnter = ( childItems , listItem)=> {
    this.setState({childItems: childItems
         ,listClassNames: [styles.List , styles.ListHover]
        , childListClassNames: [styles.ChildList , styles.ChildListHover],
        })
    if (window.innerWidth > 900) {
        this.setState({indexOfActiveListItem : [...this.props.category].indexOf(listItem)})
    }
   
}
ListItemMouseLeave = (event)=> {
    this.setState({childItems: null})
}
    render(){
        let list = 
        (<div style={{width:'100%'}}>
            <div className={styles.Loading}>Loading Data ...</div>
            <div className={styles.Loading}>Loading Data ...</div>
            <div className={styles.Loading}>Loading Data ...</div>
            <div className={styles.Loading}>Loading Data ...</div>
            <div className={styles.Loading}>Loading Data ...</div>
            <div className={styles.Loading}>Loading Data ...</div>
        </div>
            );

            if (this.props.category) {
                list = this.props.category.map((listItem)=>{
           
                    const indexOfActiveListItem = [...this.props.category].indexOf(listItem);
                    return (
                    <ListItem 
                    key = {listItem.id}
                    active = {indexOfActiveListItem === this.state.indexOfActiveListItem ? true : false}
                    hasIcon = {true}
                    mouseEnter={()=>{if(window.innerWidth > 900){this.ListItemMouseEnter(listItem.childs , listItem)}}}
                    click={()=>{this.ListItemMouseEnter(listItem.childs , listItem);
                       }}>
                    <img className={styles.Icon} src={listItem.image} alt={listItem.title} />
                    {
                        window.innerWidth > 900 ?
                        <Link 
                        to={'/Topic/' + listItem.path}  
                        onClick={this.props.CloseList}
                        className={styles.Link} 
                        key={listItem.title}>
                        {listItem.name}
                        </Link>
                        :
                        <span 
                        className={styles.Link} 
                        key={listItem.title}>{listItem.name}</span>
                        }
                    
                    </ListItem>
                    )
                })
            }
            
        let childList = null;
        if(this.state.childItems){
            childList = (
                <ul  className={this.state.childListClassNames.join(' ') }>

                {
                    window.innerWidth < 900 ?
                    <ListItem isBackButton   hasIcon = {true} click ={this.ListItemMouseLeave}>منوی اصلی</ListItem>
                    :null}
               { this.state.childItems.map((childItem)=>{
                return (
                    <ListItem key={childItem.id} click={this.props.onCloseSideDrawer} >
                        <Link 
                        to={'/Topic/' + childItem.parent + '/'  + childItem.path} 
                        className={styles.Link} 
                        onClick={this.props.CloseList}
                        key={childItem.id}>{childItem.name}</Link>
                    </ListItem>
                )
            })}
            </ul>)
        }
       return (
        <>
            <ul className={this.state.listClassNames.join(' ')}>
                {list}
            </ul>
            
            {childList}
            
        </>
       )
        
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onCloseSideDrawer : () => dispatch(actions.CloseSideDrawer()),
       
        
    }
}
const mapStateToProps = state => {
    return {
        category : state.category
    }
}
export default connect(mapStateToProps , mapDispatchToProps)(List);