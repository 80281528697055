import React, { Component } from 'react';
import styles from './CoursePage.module.css';
import VideoPlayer from '../../Components/VideoPlayer/VideoPlayer';
import Slider from '../../Components/Sliders/Slider/Slider';
import CourseModule from '../../Components/CourseModule/CourseModule';
import TimeSeter from '../../Components/Utilities/TimeSeter';
import {Helmet} from 'react-helmet';
import api from '../../api/api';

class CoursePage extends Component {
    state = 
    {
        courseId : null,
        title : null,
        description : null,
        courseModules : null,
        image : null,
        video : null,
        parts : null,
        time : null,
        courseRelatedCourses : null,
    }
        
    Start = ()=>{
        this.setState({
        courseId : null,
        title : null,
        description : null,
        courseModules : null,
        image : null,
        video : null,
        parts : null,
        time : null,
        courseRelatedCourses : null,
        })
      
        api.get('/course/' + this.props.match.params.CourseName)
        .then((res)=>{
            // console.log(res.data)
            this.setState({
                courseId : res.data.id,
                title : res.data.name,
                description : res.data.description,
                video : res.data.video,
                image : res.data.image,
                parts : res.data.parts,
                time: res.data.time
            })
        })
        .then(()=>{
            api.get('/algorithm/related?item=Course_' + this.state.courseId)
            .then((res)=>{
                // console.log(res.data , "Related Courses")
                this.setState({
                    courseRelatedCourses : res.data
                })
            })
        })
        api.get('/module/?course_path=' + this.props.match.params.CourseName)
        .then((res)=>{
            this.setState({courseModules : res.data.results})
            // console.log(res.data.results)
        })
        window.scrollTo({top:0 , left:0 , behavior:'auto'})
    }
    componentDidMount(){
      this.Start();
    }
    componentDidUpdate(prevProps){
        if(this.props.match.params.CourseName !== prevProps.match.params.CourseName){
            this.Start();
            }
    }
   render(){
    const videoJsOptions = {
        autoplay: false,
        controls: true,
        playbackRates: [0.5, 1, 1.25, 1.5, 2],
        poster : this.state.cover,
        sources: [{
          src: this.state.video,
          type: 'application/x-mpegURL',
          
          
        }],
        seekbar : true,
        plugins : {
            seekButtons : {
                forward : 15,
                back : 15
            }
        }
      } 
    let CourseTime = 'بدون زمان';
    if (this.state.time) {
        CourseTime = TimeSeter(this.state.time)
     
    }
    let CourseModules = (
        <>
        <CourseModule Loading />
        <CourseModule Loading />
        <CourseModule Loading />
        </>
    );
    if (this.state.courseModules) {
        CourseModules = (
            <>
            {
            this.state.courseModules.map((courseModule) => {
                return <CourseModule
                CoursePath = {this.props.match.params.CourseName} 
                Title = {courseModule.title}
                id = {courseModule.id}
                key = {courseModule.id}
                ModuleOrder = {courseModule.order}
                
                 />
            })}
            </>
        );
    }
     
    
    
      let Description = (
          <div className={[styles.Description , styles.Loading].join(' ')}>

          </div>
      )
      if (this.state.image 
        && this.state.title 
        && this.state.description) {

         Description = (  <div className={styles.Description}>
            <img className={styles.Image} src={this.state.image} alt={this.state.title}/>
            <h1 className={styles.Title}>{this.state.title}</h1>
        <div className={styles.DetailsContainer}>
    <div className={styles.CourseCountContainer}>
    <div className={styles.IconContainer}>
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="16" height="14" viewBox="0 0 16 14">
    <defs>
        <clipPath id="clip-path">
        <rect width="16" height="14" fill="none"/>
        </clipPath>
    </defs>
    <g id="Menu" clipPath="url(#clip-path)">
        <path id="Menu-2"  d="M0,14V12H10v2ZM0,8V6H16V8ZM0,2V0H16V2Z" fill="#FF7F50"/>
    </g>
    </svg>
    </div>
    <h6 className={styles.CourseCount}>{this.state.parts.toString().toPersinaDigit()} قسمت</h6>
    </div>
    <div className={styles.CourseTimeContainer}>
    <div className={styles.IconContainer}>
    <svg id="clock" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g id="Group_47"  transform="translate(7.381 3.712)">
        <g id="Group_46" >
        <path id="Path_209" d="M239.639,124.48l-2.231-1.673V119.4a.62.62,0,0,0-1.239,0v3.718a.619.619,0,0,0,.248.5l2.479,1.859a.62.62,0,0,0,.744-.992Z" transform="translate(-236.169 -118.779)" fill="#FF7F50"/>
        </g>
    </g>
    <g id="Group_49" >
        <g id="Group_48" >
        <path id="Path_210"  d="M8,0a8,8,0,1,0,8,8A8.009,8.009,0,0,0,8,0ZM8,14.761A6.761,6.761,0,1,1,14.761,8,6.769,6.769,0,0,1,8,14.761Z" fill="#FF7F50"/>
        </g>
    </g>
    </svg>
    </div>
    <h6 className={styles.CourseTime}>{CourseTime}</h6>
    </div>
</div>
            <h3 className={styles.OverViewTitle}>توضیحات</h3>
            <p className={styles.OverView}>{this.state.description}</p>
        </div>)
      }
      let MetaTitle =  "براکت آکادمی -" + this.state.title ? this.state.title : '';
       let MetaDescription = "براکت آکادمی - "  + this.state.description ? this.state.description : '';
       let MetaVideoUrl = this.state.video ? this.state.video : '';
       let MetaCoverImage = this.state.image ? this.state.image : '';
       return (
           <>
            <Helmet>
            <title>{MetaTitle}</title>
            <meta name="description" content={MetaDescription} />
            <meta name="title" content={MetaTitle} />
            <meta property="og:type" content="video"/>
            <meta property="og:title" content={MetaTitle}/>
            <meta property="og:description" content={MetaDescription}/>
            {/* Video Meta Tags */}
            <meta property="og:video:url" content={MetaVideoUrl}/>
            <meta property="og:video:secure_url" content={MetaVideoUrl}/>
            <meta property="og:video:type" content="video/mp4" />
            <meta property="og:video:width" content="640" />
            <meta property="og:video:height" content="360" />
            {/* Image Meta Tags */}
            <meta property="og:image" content={MetaCoverImage}/>
            <meta property="og:image:secure_url" content={MetaCoverImage}/>
            <meta property="og:image:width" content="640" />
            <meta property="og:image:height" content="360" />
            <meta property="og:image:type" content="image/jpg" />
            </Helmet>
           <div className={styles.CoursePage}>
               <div className={styles.DescriptionsContainer}>
                   {Description}
                    <div className={[styles.VideoPlayerContainer , this.state.video ? null : styles.Loading].join(' ')}>
                   {this.state.video ? <VideoPlayer {...videoJsOptions} /> : null}

                    </div>
               </div>

                
            {CourseModules}
            
            <Slider 
            slides={this.state.courseRelatedCourses}
            title='آموزش‌های مرتبط' 
            PathPrefix="/Course/"/>
            
           </div>
           </>
       )
   }

}

export default CoursePage;