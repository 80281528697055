import React from "react";
import styles from "./CourseTypeIcon.module.css";
const CourseTypeIcon = (props) => {
  let status = null;
  switch (props.status) {
    case "Article":
      status = (
        <div className={styles.Text}>
          <svg
            id="article"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 22.847 33.82"
          >
            <path
              id="Path_215"
              d="M95.015,220.108a.879.879,0,0,1-1.243,0l-1.136-1.136L90,221.608v.515h12.3v-.515l-4.394-4.394Zm0,0"
              transform="translate(-84.727 -202.549)"
            />
            <path
              id="Path_216"
              d="M90,156.787l2.015-2.015a.878.878,0,0,1,1.243,0l1.136,1.136,2.894-2.893a.878.878,0,0,1,1.243,0l3.772,3.772V151H90Zm0,0"
              transform="translate(-84.727 -140.756)"
            />
            <path
              id="Path_217"
              d="M21.969,0H.879A.94.94,0,0,0,0,.991V32.829a.94.94,0,0,0,.879.991h21.09a.94.94,0,0,0,.879-.991V.991A.94.94,0,0,0,21.969,0ZM9.666,29.79H4.394a1,1,0,0,1,0-1.982H9.666a1,1,0,0,1,0,1.982Zm0-3.963H4.394a1,1,0,0,1,0-1.982H9.666a1,1,0,0,1,0,1.982Zm8.787,3.963H13.181a1,1,0,0,1,0-1.982h5.272a1,1,0,0,1,0,1.982Zm0-3.963H13.181a1,1,0,0,1,0-1.982h5.272a1,1,0,0,1,0,1.982Zm.879-4.954a.94.94,0,0,1-.879.991H4.394a.94.94,0,0,1-.879-.991V8.983a.94.94,0,0,1,.879-.991h14.06a.94.94,0,0,1,.879.991ZM18.454,6.011H4.394a.94.94,0,0,1-.879-.991.94.94,0,0,1,.879-.991h14.06a.94.94,0,0,1,.879.991A.94.94,0,0,1,18.454,6.011Zm0,0"
            />
          </svg>
        </div>
      );

      break;

    case "Video":
      status = (
        <div className={styles.Video}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.33 21.345">
            <g id="video-camera" transform="translate(0 -96.512)">
              <g id="Group_87" transform="translate(0 96.512)">
                <g id="Group_86" transform="translate(0 0)">
                  <path
                    id="Path_218"
                    d="M20.55,96.512H3.975A3.987,3.987,0,0,0,0,100.487v13.4a3.987,3.987,0,0,0,3.975,3.975H20.55a3.987,3.987,0,0,0,3.975-3.975v-13.4A3.961,3.961,0,0,0,20.55,96.512Z"
                    transform="translate(0 -96.512)"
                  />
                </g>
              </g>
              <g id="Group_89" transform="translate(26.512 98.634)">
                <g id="Group_88" transform="translate(0 0)">
                  <path
                    id="Path_219"
                    d="M348.38,123.911a2.024,2.024,0,0,0-.676.278l-6.2,3.577v9.222l6.241,3.577a2.363,2.363,0,0,0,3.259-.874,2.428,2.428,0,0,0,.318-1.192V126.217A2.392,2.392,0,0,0,348.38,123.911Z"
                    transform="translate(-341.504 -123.847)"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>
      );

      break;
    case "Quiz":
      status = (
        <div className={styles.Quiz}>
          <span>?</span>
        </div>
      );
      break;
    case "ComingSoon":
      status = (
        <div className={styles.ComingSoon}>
         <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 48 48"><path d="M32.82,35.58l2.7-2.7L26,23.28V11.22h-3.6v13.5ZM24,48a23.22,23.22,0,0,1-9.3-1.89A24.18,24.18,0,0,1,1.89,33.3,23.22,23.22,0,0,1,0,24a23.22,23.22,0,0,1,1.89-9.3A24.18,24.18,0,0,1,14.7,1.89,23.22,23.22,0,0,1,24,0a23.22,23.22,0,0,1,9.3,1.89A24.18,24.18,0,0,1,46.11,14.7,23.22,23.22,0,0,1,48,24a23.22,23.22,0,0,1-1.89,9.3A24.18,24.18,0,0,1,33.3,46.11,23.22,23.22,0,0,1,24,48Zm0-24Zm0,20.4a19.64,19.64,0,0,0,14.4-6,19.64,19.64,0,0,0,6-14.4,19.64,19.64,0,0,0-6-14.4A19.64,19.64,0,0,0,24,3.6a19.64,19.64,0,0,0-14.4,6A19.64,19.64,0,0,0,3.6,24a19.64,19.64,0,0,0,6,14.4A19.64,19.64,0,0,0,24,44.4Z"/></svg>
        </div>
      );
      break;
    default:
      status = null;
      break;
  }
  return status;
};

export default CourseTypeIcon;
