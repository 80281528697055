import React, { Component } from 'react';
// import CourseSubTopic from '../../Components/CourseSubTopic/CourseSubTopic';
import styles from './SubTopic.module.css';
import Subject from '../../Components/Subject/Subject';
import { connect } from 'react-redux';
// import CourseSubTopicLoading from '../../Components/CourseSubTopic/CourseSubTopicLoading/CourseSubTopicLoading';
import {Helmet} from 'react-helmet';
import CourseTopicSkeleton from '../../Components/CourseTopic/CourseTopicSkeleton/CourseTopicSkeleton';
import CourseTopic from '../../Components/CourseTopic/CourseTopic';
import CourseGridContainer from '../../Components/GridContainers/CourseGridContainer/CourseGridContainer';
import TopicSearch from '../../Components/TopicSearch/TopicSearch';
import api from '../../api/api';

class SubTopic extends Component {
    state ={
        subTopics : null,
        searchValue : ''
    }
    Start = ()=>{
        let Headers = {};
        if (this.props.token) {
            Headers = {
                Authorization : 'Token ' + this.props.token
            };
        }
        else {
            Headers = {};
        }
        api.get('/course/?category_path=' + this.props.match.params.SubTopicName , {headers : Headers})
        .then((res)=>{
            this.setState({subTopics : res.data.results});
            // console.log(res.data)
            if (res.data.results.length === 0) {
                this.props.history.push('/NotFound')
            }
        })
        window.scrollTo({top: 0 , left:0 , behavior:'auto'});
    }
    componentDidMount(){
       this.Start();

    }
    componentDidUpdate(prevProps){
       if(this.props.match.params.SubTopicName !== prevProps.match.params.SubTopicName){
        this.setState({subTopics : null});
        this.Start();
        }
    }
    render(){
        
        let subTopic = null;
        
        if (subTopic === null && this.props.category) {
            this.props.category.forEach((topic)=> {
                if(topic.path.toString() === this.props.match.params.TopicName.toString()){  
                    
                    topic.childs.forEach((sub)=>{
                        if (sub.path.toString() === this.props.match.params.SubTopicName.toString()) {
                           
                            subTopic = sub;
                        }
                        else {
                            return 
                        }
                    })

                }
                else {
                    return
                }
            })
            
        }
        let subject = <Subject Loading />;
        let Title = '';
        if (subTopic) {
            Title = subTopic.name;
            subject = <Subject Image={subTopic.image} Name={subTopic.name} />
        }
        let SubTopics = (<CourseTopicSkeleton />);
        if (this.state.subTopics && this.state.subTopics.length > 0) {
            SubTopics = this.state.subTopics.filter(sub=> sub.name.toLowerCase().includes(this.state.searchValue.toLowerCase())).map((subTopic)=>{
                // console.log(subTopic)
                return <CourseTopic
                Path = {'/Course/' + subTopic.path}
                Progress = {subTopic.progress}
                CourseTime = {subTopic.time}
                CourseCount = {subTopic.parts.toString()}
                Title={subTopic.name}
                Src={subTopic.image}
                Description = {subTopic.description}
                key={subTopic.id} />
            });
        }
        let MetaImage = subTopic ? subTopic.image : '';
        return(
        <>
        <Helmet>
            <title>
                براکت آکادمی - 
                {Title}
            </title>
            <meta 
            name="description" 
            content={"براکت آکادمی - " 
            + Title} />
             <meta name="title" content={"براکت آکادمی" - Title} />
            <meta property="og:type" content="topic"/>
            <meta property="og:title" content={"براکت آکادمی" - Title}/>
            <meta property="og:description" content={"براکت آکادمی" - Title}/>
            {/* Image Meta Tags */}
            <meta property="og:image" content={MetaImage}/>
            <meta property="og:image:secure_url" content={MetaImage}/>
            <meta property="og:image:width" content="256" />
            <meta property="og:image:height" content="256" />
            <meta property="og:image:type" content="image/png" />
        </Helmet>
        <div className={styles.SubTopicContainer}>
        {subject}
        <TopicSearch onChange={(event)=>{
                this.setState({searchValue : event.target.value})
            }} searchValue={this.state.searchValue} />
        
        <CourseGridContainer className={styles.SubTopic}>
        {SubTopics}
        </CourseGridContainer>
        </div>
       
        </>
        )
    }
}
const mapStateToProps = state => {
    return {
        category : state.category,
        token : state.token
    }
}
export default connect(mapStateToProps)(SubTopic);