import React, { useEffect, useState } from 'react';
import styles from './Comment.module.css';
import AvatarPNG from '../../Avatar/Avatar.png';
import Tooltip from '../../Tooltip/Tooltip';
import Paragraph from '../../Paragraph/Paragraph';
import api from '../../../api/api';
const Comment = (props)=> {
    const [Like ,ChangeLike] = useState({
        likeCounts : props.LikeCounts,
        liked: props.Liked});
        useEffect(()=>{
            if (!Like.liked) {
                ChangeLike({...Like , liked : props.Liked})
            }
        } , [props.Liked])
    const CreateAtCalc = ()=>{
       
        const CurrentDate = new Date();
        const CurrentYear = +CurrentDate.getFullYear();
        const CurrentMonth = +CurrentDate.getMonth();
        const CurrentDay = +CurrentDate.getDate();
        const CurrentHour = +CurrentDate.getHours();
        const CurrentMinute = +CurrentDate.getMinutes();
        if (props.CreatedAt) {
            const CommentDate = new Date(props.CreatedAt);
            const CommentYear = +CommentDate.getFullYear();
            const CommentMonth = +CommentDate.getMonth();
            const CommentDay = +CommentDate.getDate();
            const CommentHour = +CommentDate.getHours();
            const CommentMinute = +CommentDate.getMinutes();
            // console.log(CommentYear ,CommentMonth ,CommentDay ,CommentHour ,CommentMinute , 'CommentTime' , props.CommentId)
            // console.log(CurrentYear ,CurrentMonth ,CurrentDay ,CurrentHour ,CurrentMinute , 'CurrentTime' , props.CommentId  )
            const YearDeffer = (CurrentYear - CommentYear);
            const MonthDeffer = (CurrentMonth - CommentMonth);
            const DayDeffer = (CurrentDay - CommentDay);
            const HourDeffer = (CurrentHour - CommentHour);
            const MinuteDeffer = (CurrentMinute - CommentMinute);
            if ( YearDeffer > 0) {

                return YearDeffer.toString().toPersinaDigit() + ' سال '
            }
            else {
                if (MonthDeffer> 0) {
                    return MonthDeffer.toString().toPersinaDigit() + ' ماه '
                    
                }
                else {
                    if (DayDeffer > 0) {
                        return DayDeffer.toString().toPersinaDigit() + ' روز '
                        
                    }
                    else {
                        if (HourDeffer > 0) {
                            return HourDeffer.toString().toPersinaDigit() + ' ساعت '
                            
                        }
                        else {
                            if (MinuteDeffer > 0) {
                                return MinuteDeffer.toString().toPersinaDigit() + ' دقیقه '
                                
                            } 
                            else {
                                return 'چند لحظه'
                            }
                        }

                    }

                }
                
            }
            
            
               

            
        }
    }
    const LikeHandler = ()=> {
        if (Like.liked === false) {
            api.post('/activity/add' , {
                activity_type : 'LI',
                activity_on : 'Comment_' + props.CommentId
            } , {
                headers : {
                    Authorization : 'Token ' + props.Token
                }
            })
            .then((res)=>{
                ChangeLike((prevState)=>{
                    return {
                        likeCounts : prevState.likeCounts + 1,
                        liked : true
                    }
                })
            })
             
        }
        else {
            api.delete('/activity/delete?activity_type=LI&activity_on=Comment_'
             + props.CommentId  , {
                headers : {
                    Authorization : 'Token ' + props.Token
                }
            }).then((res)=>{
                ChangeLike((prevState)=>{
                    return {
                        likeCounts : prevState.likeCounts - 1,
                        liked : false
                    }
                })
            })
        }

    }
    return (
        <div className={styles.Comment}>
            <div className={styles.Avatar}>
                <img src={props.Image ? props.Image : AvatarPNG} alt={props.Name} />
            </div>
            <div className={styles.Data}>
                <div className={styles.TopComment}>
                    <div className={styles.Name}> {props.Name} </div>
                    <div className={styles.Elapse}>{CreateAtCalc()} پیش</div>
                </div>
                <Paragraph className={styles.CommentPara}>{props.Comment}</Paragraph>
                <div className={styles.BottomComment}>
                    <div className={styles.Buttons}>
                    <div className={styles.Replay}><span>پاسخ</span> 
                     {props.LogedIn ? null :<div className={styles.TooltipContainer}> <Tooltip>ابتدا وارد شوید.</Tooltip> </div>}</div>
                    <div onClick={LikeHandler} className={styles.Like}><span>لایک</span>
                    {props.LogedIn ? null :<div className={styles.TooltipContainer}> <Tooltip>ابتدا وارد شوید.</Tooltip> </div>}</div>
                    </div>
                    <div className={[styles.LikeCountsContainer , Like.liked ? styles.Liked : null].join(' ')}>
                        <span className={styles.LikeCounts}>{Like.likeCounts.toString().toPersinaDigit()}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve"><g><g><path d="M376,30c-27.783,0-53.255,8.804-75.707,26.168c-21.525,16.647-35.856,37.85-44.293,53.268    c-8.437-15.419-22.768-36.621-44.293-53.268C189.255,38.804,163.783,30,136,30C58.468,30,0,93.417,0,177.514    c0,90.854,72.943,153.015,183.369,247.118c18.752,15.981,40.007,34.095,62.099,53.414C248.38,480.596,252.12,482,256,482    s7.62-1.404,10.532-3.953c22.094-19.322,43.348-37.435,62.111-53.425C439.057,330.529,512,268.368,512,177.514    C512,93.417,453.532,30,376,30z"></path></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Comment;