import React from 'react';
import getColor from '../Utilities/ColorGenerator';
import styles from './Subject.module.css';
const subject = (props)=> {
  let Color;
 
    Color = Color ? Color : getColor();
    return (
        <div style={{backgroundColor : Color}} className={styles.Subject}>{
        props.Loading ? 
        <>
        
        <div className={styles.ImageLoading}></div>

        <div className={styles.NameLoading}></div>
        
        </>
        :
        <>
        <div className={styles.ImageContaniner}>
        <img src={props.Image} alt={props.Name} />
        </div>
        <h1 className={styles.Title}>{props.Name}</h1>
        </>}
        </div>
    )
}
export default subject;