import React, { Component } from "react";
import LikeButton from "../../../Components/Buttons/LikeButton/LikeButton";
import SaveButton from "../../../Components/Buttons/SaveButton/SaveButton";
import ShareButton from "../../../Components/Buttons/ShareButton/ShareButton";
import Paragraph from "../../../Components/Paragraph/Paragraph";
import styles from "./ArticleContent.module.css";
import hljs from "highlight.js";
import "highlight.js/styles/monokai-sublime.css";
import { withRouter } from "react-router";

class ArticleContent extends Component {
  state = {
    isViewed: false,
    smallScreen: false,
    
  };
  addView = ()=>{
    
      setTimeout(() => {
        this.props.AddViewHandler();
      }, 1000);
    
    
  }
  componentDidMount() {
    this.addView();
    if (window.innerWidth < 1200) {
      this.setState({ smallScreen: true });
    }

    window.addEventListener("resize", () => {
      if (window.innerWidth < 1200 && !this.state.smallScreen) {
        this.setState({ smallScreen: true });
      }
      if (window.innerWidth > 1200 && this.state.smallScreen) {
        this.setState({ smallScreen: false });
      }
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.article !== this.props.article) {
      this.addView();
      const PreElements = document.querySelectorAll("pre");
      PreElements.forEach((el) => {
        el.style.position = 'relative'
        const copyBtn = document.createElement('button');
        copyBtn.className = styles.CopyBtn;
        copyBtn.textContent = '';
        copyBtn.addEventListener('click', ()=>{
          navigator.clipboard.writeText(el.querySelector('code').textContent);
        })
        const copiedTooltip = document.createElement('div');
        copiedTooltip.className = styles.CopiedTooltip;
        copiedTooltip.textContent = 'کپی شد!';
        copyBtn.appendChild(copiedTooltip);
        const span = el.querySelector("span");
        const code = document.createElement("code");
        if (span) {
          code.innerHTML = span.innerHTML;
          el.replaceChild(code, span);
        }
        else {
          code.innerHTML = el.innerHTML;
          el.innerHTML = '';
          el.appendChild(code);
          
        }
        el.appendChild(copyBtn)

      });
      hljs.highlightAll();
      // hljs.highlightAuto();
    }
  }
  render() {
    return (
      <>
        <div className={styles.TopContainer}>
          <div className={styles.DetilsAndButtons}>
            <h1
              className={[
                styles.Title,
                this.props.title ? null : styles.Loading,
              ].join(" ")}
            >
              {this.props.title}
            </h1>
            <div className={styles.Description}>
              <h2 className={styles.DescriptionTitle}>توضیحات</h2>
              <Paragraph hasHTMLElement>{this.props.description}</Paragraph>
            </div>
            <div className={styles.ButtomPart}>
              
              {this.props.CourseDetail}
              <div className={styles.ButtonsContainer}>
                <LikeButton
                  IsLiked={this.props.isLiked}
                  Count={this.props.likes}
                  LikeHandler={this.props.LikeHandler}
                />
                <SaveButton
                  SaveHandler={this.props.SaveHandler}
                  isSaved={this.props.isSaved}
                />
                <ShareButton Position="Right" />
              </div>
            </div>
          </div>
          <div
            className={[
              styles.CoverImageContainer,
              this.props.cover ? null : styles.Loading,
            ].join(" ")}
          >
            {this.props.cover ? (
              <img
                alt={this.props.title}
                className={styles.ArticleCover}
                src={this.props.cover}
              />
            ) : null}
          </div>
        </div>
        <div className={styles.ArticleContent}>
          {this.props.Description}

          <article
            dangerouslySetInnerHTML={{ __html: this.props.article }}
          >
          </article>
          
          {this.props.article ? null : (
            <>
              <Paragraph></Paragraph>
              <Paragraph></Paragraph>
              <Paragraph></Paragraph>
            </>
          )}

        </div>
      </>
    );
  }
}
export default withRouter(ArticleContent);
