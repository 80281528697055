import React, { useState } from "react";
import { withRouter } from "react-router";
import styles from "./CourseModuleItem.module.css";
import CourseTypeIcon from "../../Icons/CourseTypeIcon/CourseTypeIcon";
import PlayingIcon from "../../Icons/PlayingIcon/PlayingIcon";
import { Link } from "react-router-dom";
import TimeSeter from "../../Utilities/TimeSeter";
const CourseModuleItem = (props) => {
  const [state, setState] = useState({
    isHover: false,
  });
  const [isChecked, setIsChecked] = useState(props.isChecked);

  const Hover = () => {
    setState({ isHover: true });
  };
  const unHover = () => {
    setState({ isHover: false });
  };
  let buttonText = null;
  let duration = null;
  let ClassName = null;
  const durationArray = [...props.duration.split(":")];
  const Seconds = Math.floor(+durationArray[2]);
  durationArray[2] = Seconds.toString();
  switch (props.status) {
    case "Article":
      ClassName = styles.Text;
      buttonText = "مطالعه";
      duration = TimeSeter(durationArray.join(":"));

      break;
    case "Video":
      duration = TimeSeter(durationArray.join(":"));
      buttonText = "پخش";
      ClassName = styles.Video;
      break;
    case "Quiz":
      duration = TimeSeter(durationArray.join(":"));
      buttonText = "شرکت";
      ClassName = styles.Quiz;
      break;
    case "ComingSoon":
      duration = "به زودی";
      buttonText = "به زودی";
      ClassName = styles.ComingSoon;
      break;

    default:
      buttonText = null;
      duration = "00:00:00";
      ClassName = null;
      break;
  }
  let isActive = false;
  // console.log(props.match.params.ContentOrder)
  if (+props.match.params.ContentOrder === props.index && props.VideoPage) {
    isActive = true;
  }
  if (isActive && !isChecked) {
    setIsChecked(true);
  }
  let Status = null;
  if (isActive) {
    Status = (
      <div className={styles.Play}>
        <PlayingIcon Type={props.status} />
      </div>
    );
  } else if (isChecked) {
    Status = (
      <div className={styles.Checked}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="Capa_1"
          viewBox="0 0 515.556 515.556"
        >
          <path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z" />
        </svg>
      </div>
    );
  } else if (!state.isHover) {
    Status = <div className={styles.Duration}>{duration}</div>;
  } else {
    Status = <button className={styles.Button}>{buttonText}</button>;
  }
  return (
    <Link
      onClick={() => {
        setIsChecked(true);
      }}
      onMouseOver={Hover}
      onTouchStart={Hover}
      onMouseLeave={unHover}
      to={
        "/Content/" +
        props.status +
        "/" +
        props.CoursePath +
        "/" +
        props.ModuleOrder +
        "/" +
        props.ContentOrder
      }
      className={[
        styles.CourseModuleItem,
        ClassName,
        isActive ? styles.IsActive : null,
      ].join(" ")}
    >
      <div className={styles.Subject}>
        <div className={styles.Index}>
          <span>{(props.index + 1).toString().toPersinaDigit()}</span>
        </div>
        <div className={styles.Line}></div>
        <CourseTypeIcon status={props.status} />
        <h1 className={styles.Title}>{props.children}</h1>
      </div>
      <div>{Status}</div>
    </Link>
  );
};

export default withRouter(CourseModuleItem);
