import React from 'react'; 
import { connect } from 'react-redux';
import styles from './List.module.css';
import * as actions from '../../../store/actions/action';
import { withRouter } from 'react-router';
import Avatar from '../../Avatar/Avatar';
import UserType from '../../UserType/UserType';
const list = (props)=> {
    let listItems = null;
    if (props.userData) {
        listItems = (
            <>
            <div className={styles.Avatar}>
                <Avatar />
            </div>
            <h3 className={styles.Name}>{props.userData.name}</h3>
            <p className={styles.Email}>{props.userData.email}</p>
            <UserType />
            <div className={styles.ButtonsContainer}>
            
            <button onClick={()=>{
                props.history.push('/Dashboard/Activities');
                props.closeList();
                props.onCloseSideDrawer();
            }} className={styles.Profile}>
                <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512"><path d="m197.332031 0h-160c-20.585937 0-37.332031 16.746094-37.332031 37.332031v96c0 20.589844 16.746094 37.335938 37.332031 37.335938h160c20.589844 0 37.335938-16.746094 37.335938-37.335938v-96c0-20.585937-16.746094-37.332031-37.335938-37.332031zm0 0"/><path d="m197.332031 213.332031h-160c-20.585937 0-37.332031 16.746094-37.332031 37.335938v224c0 20.585937 16.746094 37.332031 37.332031 37.332031h160c20.589844 0 37.335938-16.746094 37.335938-37.332031v-224c0-20.589844-16.746094-37.335938-37.335938-37.335938zm0 0"/><path d="m474.667969 341.332031h-160c-20.589844 0-37.335938 16.746094-37.335938 37.335938v96c0 20.585937 16.746094 37.332031 37.335938 37.332031h160c20.585937 0 37.332031-16.746094 37.332031-37.332031v-96c0-20.589844-16.746094-37.335938-37.332031-37.335938zm0 0"/><path d="m474.667969 0h-160c-20.589844 0-37.335938 16.746094-37.335938 37.332031v224c0 20.589844 16.746094 37.335938 37.335938 37.335938h160c20.585937 0 37.332031-16.746094 37.332031-37.335938v-224c0-20.585937-16.746094-37.332031-37.332031-37.332031zm0 0"/></svg>
                <span>فعالیت‌ها</span></button>
            <button onClick={()=>{
                props.history.push('/Dashboard/Saved');
                props.closeList();
                props.onCloseSideDrawer();
            }} className={styles.Profile}>
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 423.936 423.936"  xmlSpace="preserve">
<g>
	<g>
		<path d="M327.68,0H96.256c-22.528,0-40.96,18.432-40.96,40.96v357.376c0,9.728,5.632,18.944,14.336,23.04    c9.216,4.096,19.456,3.072,27.136-3.072l0.512-0.512l114.688-96.768l114.688,96.768l0.512,0.512    c4.608,3.584,10.24,5.632,15.872,5.632c3.584,0,7.68-1.024,11.264-3.072c8.704-4.096,14.336-13.312,14.336-23.04V40.96    C368.64,18.432,350.208,0,327.68,0z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
                </svg>
                <span>ذخیره شده</span></button>
            <button onClick={()=>{
                props.history.push('/Dashboard/Settings');
                props.closeList();
                props.onCloseSideDrawer();
            }} className={styles.Profile}>
                <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" ><path d="m22.963 14.676c-.001-.001-.003-.002-.004-.004l-1.538-1.413c.052-.426.079-.848.079-1.259s-.027-.833-.079-1.259l1.542-1.417c.509-.479.625-1.239.28-1.855l-1.697-2.934c-.34-.605-1.079-.893-1.756-.682l-1.996.63c-.677-.527-1.407-.951-2.175-1.263l-.461-2.049c-.151-.678-.764-1.171-1.458-1.171h-3.4c-.694 0-1.307.493-1.458 1.17l-.461 2.05c-.768.312-1.498.736-2.175 1.263l-1.998-.63c-.673-.209-1.414.076-1.75.676l-1.704 2.946c-.342.61-.226 1.37.288 1.853l1.538 1.413c-.053.426-.08.848-.08 1.259s.027.833.079 1.259l-1.542 1.417c-.509.479-.625 1.239-.28 1.854l1.697 2.934c.34.606 1.078.893 1.756.683l1.996-.63c.677.527 1.407.951 2.175 1.263l.461 2.048c.151.679.764 1.172 1.458 1.172h3.4c.694 0 1.307-.493 1.458-1.17l.461-2.05c.768-.312 1.498-.735 2.175-1.263l1.998.63c.675.211 1.414-.077 1.751-.677l1.704-2.946c.341-.609.225-1.369-.284-1.848zm-10.963 2.324c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z"/></svg>
                <span>تنظیمات</span>
                </button>
            <div className={styles.Line}></div>
            <button onClick={()=>{
                props.onLogout();
                props.closeList();
                props.onCloseSideDrawer();
                localStorage.removeItem('token');
                }} className={styles.Logout}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.00533 512"><path d="m320 277.335938c-11.796875 0-21.332031 9.558593-21.332031 21.332031v85.335937c0 11.753906-9.558594 21.332032-21.335938 21.332032h-64v-320c0-18.21875-11.605469-34.496094-29.054687-40.554688l-6.316406-2.113281h99.371093c11.777344 0 21.335938 9.578125 21.335938 21.335937v64c0 11.773438 9.535156 21.332032 21.332031 21.332032s21.332031-9.558594 21.332031-21.332032v-64c0-35.285156-28.714843-63.99999975-64-63.99999975h-229.332031c-.8125 0-1.492188.36328175-2.28125.46874975-1.027344-.085937-2.007812-.46874975-3.050781-.46874975-23.53125 0-42.667969 19.13281275-42.667969 42.66406275v384c0 18.21875 11.605469 34.496093 29.054688 40.554687l128.386718 42.796875c4.351563 1.34375 8.679688 1.984375 13.226563 1.984375 23.53125 0 42.664062-19.136718 42.664062-42.667968v-21.332032h64c35.285157 0 64-28.714844 64-64v-85.335937c0-11.773438-9.535156-21.332031-21.332031-21.332031zm0 0"/><path d="m505.75 198.253906-85.335938-85.332031c-6.097656-6.101563-15.273437-7.9375-23.25-4.632813-7.957031 3.308594-13.164062 11.09375-13.164062 19.714844v64h-85.332031c-11.777344 0-21.335938 9.554688-21.335938 21.332032 0 11.777343 9.558594 21.332031 21.335938 21.332031h85.332031v64c0 8.621093 5.207031 16.40625 13.164062 19.714843 7.976563 3.304688 17.152344 1.46875 23.25-4.628906l85.335938-85.335937c8.339844-8.339844 8.339844-21.824219 0-30.164063zm0 0"/></svg>
                    <span>خروج</span></button>
            </div>
            
            </>
        )
    }
    return (
        <div className={styles.List} onClick={()=>{
            props.onCloseSideDrawer();
            props.closeList();}} onMouseLeave={props.closeList}>
            {listItems}
        </div>
    )
}
const mapStateToProps = state => {
    return {
        userData : state.userData
    }
}
const mapDisptachToProps = dispatch => {
    return {
        onLogout : ()=> dispatch(actions.Logout()),
        onCloseSideDrawer : ()=> dispatch(actions.CloseSideDrawer()),
    }
}
export default connect(mapStateToProps ,mapDisptachToProps )(withRouter(list));