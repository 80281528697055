import { Link } from 'react-router-dom';
import styles from './Topic.module.css';
const Topic = (props)=>{
    
    return (
        <Link to={'/Topic/' + props.path} className={styles.Topic}>
			<img alt={props.title} src={props.src} />
            <h3>{props.title}</h3>
        </Link>
    )
}
export default Topic;