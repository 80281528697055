import React , {Component} from 'react';
import { connect } from 'react-redux';
import Avatar from '../Avatar/Avatar';
import styles from './AccountButton.module.css';
import List from './List/List';

class accountButton extends Component  {
    constructor(){
        super();
        this.accountButton = null;
    }
    state = {
        showList : false
    }
    componentDidMount(){
        document.addEventListener('click' , (event)=>{
            if (this.accountButton && this.state.showList) {
                if (!this.accountButton.contains(event.target)) {
                    this.setState({showList : false})
                    // console.log(this.accountButton)
                } 
            }
            
        })
    }
    ToggleList = (event)=>{
        this.setState((preState)=> {
            return {
                showList : !preState.showList
            }
        })
    }
    CloseList = ()=> {
        this.setState({showList : false});
    }
    render(){ 
    let AccountButton = null;
    if (this.props.token) {
        AccountButton = <div className = {[styles.Loading , this.props.white ? styles.White : null].join(' ')}></div>;
        if (this.props.userData) {
            
            AccountButton = (
            <div 
            onClick={this.ToggleList} 
            className={styles.AccountButtonContainer}
            ref={(el)=>{this.accountButton = el}}>
            <div className = {styles.ArrowContainer}>
            <div className={styles.Arrow}>
                <div className={styles.RightArrow}></div>
                <div className={styles.LeftArrow}></div>
            </div>
            </div>
           
            <p className={styles.Name}>{this.props.userData.name}</p>
            <div className={styles.AccountButton}>
            <div className={styles.Avatar}>
                    <Avatar />
                </div>
            </div>
            
            </div>
            );
        }
    }
    
    return (
        <>
        {AccountButton}
        {(this.state.showList && this.props.token) ? <List closeList={this.CloseList}/> : null}
        </>
        );}
}
const mapStateToProps = state => {
    return {
        token : state.token,
        userData : state.userData
    }
}
export default connect(mapStateToProps)(accountButton);
