import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import styles from './VideoSlider.module.css';
import React  from 'react';
// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/effect-fade/effect-fade.min.css';
import { Link } from 'react-router-dom';
import VideoItem from '../../VideoItem/VideoItem';

const VideoSlider = (props)=> {
 
  let slides = null;
  if (props.slides) {
    if (props.slides.length > 0) {
      slides =  props.slides.map((slide)=>{
        return <SwiperSlide> <VideoItem
        title={slide.content.title}
        cover={slide.content.cover}
        courseImage={slide.course.image}
        topic={slide.course.name}
        duration={slide.content.duration.split(".")[0]}
        topicLink={slide.course.path}
        videoLink={
          "Content/Video/" +
          slide.course.path +
          "/" +
          slide.module.order +
          "/" +
          slide.content.order
        }
       
        key={slide.content.id}
      />
          </SwiperSlide> })
        
    }
    else {
      slides = <SwiperSlide className={styles.SwiperSlide}>
        <span className={styles.NoDataFallback}>اطلاعاتی برای نمایش وجود ندارد</span>
        </SwiperSlide>
    }
  }
    SwiperCore.use([Navigation]);
  return (
      <div className={styles.Slider}>
          <div className={styles.TextContainer}>
          <h1 className={styles.Title}>{props.title}</h1>
          {props.MoreButton ? <div className={styles.MoreButton}>
          <Link className={styles.Link}>همه</Link>
          <div className={styles.Arrow}>
                <div className={styles.RightArrow}></div>
                <div className={styles.LeftArrow}></div>
            </div>
          </div> : null}
          </div>
        <div className={styles.Swiper}>
    {slides ? <Swiper className={styles.SwiperSlider}
      autoHeight='true'
      navigation
      onSlideChange={() => {}}
      onSwiper={(swiper) => {}}
      breakpoints={{
        400: {
          slidesPerView: 1,
          spaceBetween: 16
         
        },
        // when window width is >= 480px
        750: {
          slidesPerView: 2,
          spaceBetween: 32
          
        },
        
        1100: {
          slidesPerView: 3,
          spaceBetween: 32
        },
        1350: {
          slidesPerView: 4,
          spaceBetween: 32
        },
        1650: {
          slidesPerView: 5,
          spaceBetween: 32
        }
      }}
        
    >

      {slides}
      
    </Swiper> : 
    <span>در حال دریافت اطلاعات ...</span>}
    </div>
    </div>
  );
};
export default VideoSlider;