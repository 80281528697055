import React, { Component } from "react";
import { connect } from "react-redux";
import Congratulations from "../../../Components/Congratulations/Congratulations";
import Paragraph from "../../../Components/Paragraph/Paragraph";
import MultipleChoice from "../../../Components/Quizes/MultipleChoice/MultipleChoice";
import styles from "./QuizContent.module.css";
import * as actions from "../../../store/actions/action";
import { withRouter } from "react-router";
import ShareButton from "../../../Components/Buttons/ShareButton/ShareButton";
import api from "../../../api/api";

class QuizContent extends Component {
  state = {
    isChecked: false,
    answers: {},
    results: {},
    score: 0,
  };
  componentDidUpdate(prevProps) {
    if (this.props.match.params !== prevProps.match.params) {
      this.setState({
        isChecked: false,
        answers: {},
        results: {},
        score: 0,
      });
    }
  }
  FinishHandler = () => {
    this.props.setLoading();

    api
      .post(
        "/item/quizcheck/" + this.props.quizId,
        this.state.answers
      )
      .then((res) => {
        const resultsCopy = this.state.results;
        const UpdatedObj = { ...resultsCopy, ...res.data };
        this.setState((state) => {
          return {
            isChecked: true,
            results: UpdatedObj,
          };
        });
        console.log(this.state.results);
      })
      .then(() => {
        this.props.clearLoading();
        let trueAnswers = 0;
        for (let a in this.state.results) {
          if (this.state.results[a] === true) {
            trueAnswers++;
          }
        }
        const score = parseInt((trueAnswers / this.props.quiz.length) * 100);
        this.setState({ score: score });
        if (this.state.score === 100) {
          this.props.AddViewHandler();
        }
      })

      .catch(() => {
        this.props.clearLoading();
      });
  };
  onSubmit = (answer, id) => {
    const UpdatedObj = this.state.answers;
    UpdatedObj[id] = answer;
    this.setState({ answers: UpdatedObj });
  };
  render() {
    let scoreClassName = null;
    if (this.state.score <= 25) {
      scoreClassName = styles.LowScore;
    } else if (this.state.score <= 50) {
      scoreClassName = styles.MediumScore;
    } else if (this.state.score <= 75) {
      scoreClassName = styles.GoodScore;
    } else if (this.state.score <= 100) {
      scoreClassName = styles.GreatScore;
    }
    let quizes = (
      <>
        <MultipleChoice />
        <MultipleChoice />
        <MultipleChoice />
        <MultipleChoice />
      </>
    );
    if (this.props.quiz) {
      quizes = this.props.quiz.map((quiz, index) => {
        return (
          <MultipleChoice
            answer={this.state.results[quiz.id]}
            onSubmit={this.onSubmit}
            isChecked={this.state.isChecked}
            choices={quiz.choices}
            name={index}
            title={
              (index + 1).toString().toPersinaDigit() + "- " + quiz.question
            }
            id={quiz.id}
            image={quiz.image}
          />
        );
      });
    }

    return (
      <div>
        <div className={styles.TopContainer}>
          <div className={styles.DetilsAndButtons}>
            <h1
              className={[
                styles.Title,
                this.props.title ? null : styles.Loading,
              ].join(" ")}
            >
              {this.props.title}
            </h1>
            <div className={styles.Description}>
              <h2 className={styles.DescriptionTitle}>توضیحات</h2>
              <Paragraph hasHTMLElement>{this.props.description}</Paragraph>
            </div>
            <div className={styles.ButtomPart}>
              {this.props.CourseDetail}
              <div className={styles.ButtonsContainer}>
                <ShareButton Position="Right" />
              </div>
              </div>
          </div>
          <div className={styles.Cover}>
            <div className={styles.FirstQ}>?</div>
            <div className={styles.SecondQ}>?</div>
            <div className={styles.ThirdQ}>?</div>
          </div>
        </div>

        {quizes}
        <div className={styles.ButtomContainer}>
          <div className={[styles.Score, scoreClassName].join(" ")}>
            <h3>امتیاز:</h3>
            <span>{this.state.score.toString().toPersinaDigit()}%</span>
          </div>
          <button
            disabled={
              this.state.score === 100 || this.state.answers.length === 0
            }
            className={styles.FinishButton}
            onClick={() => {
              if (this.state.isChecked) {
                this.setState({ isChecked: false });
              } else {
                this.FinishHandler();
              }
            }}
          >
            {this.state.isChecked ? (
              <span>آزمون مجدد</span>
            ) : (
              <span>دیدن نتایج</span>
            )}
          </button>
          <div></div>
        </div>
        {this.state.score === 100 ? <Congratulations /> : null}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: () => dispatch(actions.LoadingSetter()),
    clearLoading: () => dispatch(actions.ClearLoading()),
  };
};
export default connect(null, mapDispatchToProps)(withRouter(QuizContent));
