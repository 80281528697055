import React, { Component } from "react";
import styles from "./CourseModule.module.css";
import CourseModuleItem from "./CourseModuleItme/CourseModuleItem";
import { connect } from "react-redux";
import api from "../../api/api";
import Button from "../Button/Button";
import { ArrowBack, Forward } from "@material-ui/icons";
import { withRouter } from "react-router-dom";
const statusMapper = (item) => {
  let status = "";
  if (item.video) {
    status = "Video";
  } else if (item.text) {
    status = "Article";
  } else if (item.problems) {
    status = "Quiz";
  } else {
    status = "ComingSoon";
  }
  return status;
};
class CourseModule extends Component {
  state = {
    prevModule: null,
    childs: null,
    nextModule: null,
  };
  Start = () => {
    let Headers = {};
    if (this.props.token) {
      Headers = {
        Authorization: "Token " + this.props.token,
      };
    } else {
      Headers = {};
    }
    if (this.props.id && !this.state.childs) {
      api
        .get("/content/?module_pk=" + this.props.id.toString(), {
          headers: Headers,
        })
        .then((res) => {
          this.setState({ childs: res.data.results });
          //   console.log(this.state.childs);
          if (this.props.ModuleContentLengthSetter) {
            this.props.ModuleContentLengthSetter(res.data.results.length);
          }
        });
    }
    if (
      this.props.id &&
      this.props.PrevModule &&
      this.props.ModuleOrder.toString() !== "0"
    ) {
      api
        .get("/content/?module_pk=" + this.props.PrevModule.id.toString(), {
          headers: Headers,
        })
        .then((res) => {
          this.setState({ prevModule: res.data.results });
          //   console.log(this.state.childs);
          if (this.props.ModuleContentLengthSetter) {
            this.props.ModuleContentLengthSetter(res.data.results.length);
          }
        });
    }
    if (this.props.id && this.props.NextModule) {
      api
        .get("/content/?module_pk=" + this.props.NextModule.id.toString(), {
          headers: Headers,
        })
        .then((res) => {
          this.setState({ nextModule: res.data.results });
          //   console.log(this.state.childs);
          if (this.props.ModuleContentLengthSetter) {
            this.props.ModuleContentLengthSetter(res.data.results.length);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    console.log(this.props.PrevModule, this.props.NextModule);
  };

  componentDidMount() {
    this.Start();
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.NextModule !== this.props.NextModule ||
      prevProps.PrevModule !== this.props.PrevModule
    ) {
      this.Start();
    }
  }

  render() {
    let contents = (
      <>
        <div
          className={[styles.CourseModuleItem, styles.Loading].join(" ")}
        ></div>
        <div className={styles.Line}></div>
        <div
          className={[styles.CourseModuleItem, styles.Loading].join(" ")}
        ></div>
        <div className={styles.Line}></div>
        <div
          className={[styles.CourseModuleItem, styles.Loading].join(" ")}
        ></div>
        <div className={styles.Line}></div>
      </>
    );
    if (this.state.childs) {
      contents = this.state.childs.map((child, index) => {
        const ChildItem = child.item;

        return (
          <div
            key={
              ChildItem.id +
              this.props.VideoPage +
              this.props.ModuleOrder +
              this.props.CoursePath +
              Math.random()
            }
          >
            <CourseModuleItem
              status={statusMapper(ChildItem)}
              isChecked={child.activity.includes("VI")}
              duration={ChildItem.duration}
              CoursePath={this.props.CoursePath}
              ModuleOrder={this.props.ModuleOrder}
              ContentOrder={child.order}
              index={index}
              VideoPage={this.props.VideoPage}
            >
              {ChildItem.title}
            </CourseModuleItem>

            <div className={styles.Line}></div>
          </div>
        );
      });
    }
    return (
      <div
        className={[
          styles.CourseModule,
          this.props.VideoPage ? styles.VideoPageCourseModule : null,
        ].join(" ")}
      >
        {
          <>
            <div className={styles.Top}>
              {this.state.prevModule ? (
                <Button
                  type="soft"
                  onClick={() => {
                    const prevPath =
                      "/Content/" +
                      statusMapper(this.state.prevModule[0].item) +
                      "/" +
                      this.props.CoursePath +
                      "/" +
                      (+this.props.ModuleOrder - 1) +
                      "/" +
                      "0";
                    this.props.history.push(prevPath);
                  }}
                >
                  <ArrowBack style={{ transform: "scale(-1)" }} />
                </Button>
              ) : (
                <div />
              )}
              <h2
                className={[
                  styles.Title,
                  this.props.Title ? null : styles.Loading,
                ].join(" ")}
              >
                {this.props.Title}
              </h2>
              {this.state.nextModule ? (
                <Button
                  onClick={() => {
                    const nextPath =
                      "/Content/" +
                      statusMapper(this.state.nextModule[0].item) +
                      "/" +
                      this.props.CoursePath +
                      "/" +
                      (+this.props.ModuleOrder + 1) +
                      "/" +
                      "0";
                    this.props.history.push(nextPath);
                  }}
                  type="soft"
                >
                  <ArrowBack />
                </Button>
              ) : (
                <div />
              )}
            </div>
            <div className={styles.Line}></div>
            {contents}
          </>
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};
export default connect(mapStateToProps)(withRouter(CourseModule));
