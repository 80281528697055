import React, { Component } from "react";
import CourseModule from "../../Components/CourseModule/CourseModule";
import styles from "./ContentPage.module.css";
import Comments from "../../Components/Comments/Comments";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import SuccessMessage from "../../Components/Message/SuccessMessage/SuccessMessage";
import { Link, Route } from "react-router-dom";
import TimeSeter from "../../Components/Utilities/TimeSeter";
import FileDownloadButton from "../../Components/Buttons/FileDownloadButton/FileDownloadButton";
import VideoContent from "./VideoContent/VideoContent";
import ArticleContent from "./ArticleContent/ArticleContent";
import Tags from "../../Components/Tags/Tags";
import QuizContent from "./QuizContent/QuizContent";
import api from "../../api/api";
class ContentPage extends Component {
  state = {
    Module: null,
    video: null,
    article: null,
    quiz: null,
    title: null,
    comments: null,
    description: null,
    views: null,
    likes: null,
    tags: null,
    ModuleContentLength: 0,
    NextCourseAllow: true,
    PrevCourseAllow: true,
    videoId: null,
    quizId: null,
    cover: null,
    contentId: null,
    isLiked: false,
    isSaved: false,
    ShowSaveSuccesMessage: false,
    courseTitle: null,
    courseDescription: null,
    courseImage: null,
    courseParts: null,
    courseTime: null,
    relatedFile: null,
    isDataFeched: false,
    NextModule: null,
    PrevModule: null,
  };
  Start = () => {
    let Headers = {};
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });

    if (this.props.token) {
      Headers = {
        Authorization: "Token " + this.props.token,
      };
    } else {
      Headers = {};
    }
    api
      .get(
        "/module/" +
          this.props.match.params.CoursePath +
          "/" +
          this.props.match.params.ModuleOrder
      )
      .then((res) => {
        this.setState({ Module: res.data });
        // console.log(this.state.Module.id)
      });
    api
      .get(
        "/module/" +
          this.props.match.params.CoursePath +
          "/" +
          (+this.props.match.params.ModuleOrder + 1)
      )
      .then((res) => {
        this.setState({ NextModule: res.data });
        // console.log(this.state.Module.id)
      })
      .catch((err) => {
        console.log(err);
      });
    api
      .get(
        "/module/" +
          this.props.match.params.CoursePath +
          "/" +
          (+this.props.match.params.ModuleOrder - 1)
      )
      .then((res) => {
        this.setState({ PrevModule: res.data });
        // console.log(this.state.Module.id)
      })
      .catch((err) => {
        console.log(err);
      });
    api
      .get(
        "/content/" +
          this.props.match.params.CoursePath +
          "/" +
          this.props.match.params.ModuleOrder +
          "/" +
          this.props.match.params.ContentOrder,
        { headers: Headers }
      )
      .then((res) => {
        console.log(res.data.tags);
        const result = res.data.item;
        // console.log(res.data.item.text);
        this.setState({
          video: result.video,
          article: result.text,
          quiz: result.problems,
          title: result.title,
          videoId: result.id,
          quizId: result.id,
          cover: result.cover,
          description: result.description,
          comments: result.comments,
          views: res.data.views,
          likes: res.data.likes,
          tags: res.data.tags,
          contentId: res.data.id,
          relatedFile: res.data.item.associated_file,
          isDataFeched: true,
        });
        res.data.activity.forEach((element) => {
          if (element === "LI") {
            this.setState({
              isLiked: true,
            });
          }
          if (element === "SA") {
            this.setState({
              isSaved: true,
            });
          }
        });
      })
      .then((res) => {
        api.get("/course/" + this.props.match.params.CoursePath).then((res) => {
          // console.log(res.data)
          this.setState({
            courseTitle: res.data.name,
            courseDescription: res.data.description,
            // video : res.data.video,
            courseImage: res.data.image,
            courseParts: res.data.parts,
            courseTime: res.data.time,
          });
        });
        api
          .get("/comment/?commented_on=Content_" + this.state.contentId, {
            headers: Headers,
          })
          .then((res) => {
            this.setState({ comments: res.data.results });
          })
          .catch(() => {});
      });
  };
  LikeHandler = () => {
    if (this.state.contentId && !this.state.isLiked) {
      api
        .post(
          "/activity/add",
          {
            activity_type: "LI",
            activity_on: `Content_${this.state.contentId}`,
          },
          { headers: { Authorization: "Token " + this.props.token } }
        )
        .then((res) => {
          // console.log(res.data + 'Liked');
          this.setState((prevState) => {
            return {
              likes: prevState.likes + 1,
              isLiked: true,
            };
          });
        });
    }
    if (this.state.contentId && this.state.isLiked) {
      api
        .delete(
          "/activity/delete?activity_type=LI&activity_on=Content_" +
            this.state.contentId,
          { headers: { Authorization: "Token " + this.props.token } }
        )
        .then((res) => {
          // console.log(res.data + 'UnLiked');
          this.setState((prevState) => {
            return {
              likes: prevState.likes - 1,
              isLiked: false,
            };
          });
        });
    }
  };
  ClearSaveSuccesMessage = () => {
    this.setState({ ShowSaveSuccesMessage: false });
  };
  SaveHandler = () => {
    if (this.state.contentId && !this.state.isSaved) {
      api
        .post(
          "/activity/add",
          {
            activity_type: "SA",
            activity_on: `Content_${this.state.contentId}`,
          },
          { headers: { Authorization: "Token " + this.props.token } }
        )
        .then((res) => {
          // console.log(res.data + 'Saved');
          this.setState({
            isSaved: true,
            ShowSaveSuccesMessage: true,
          });
        });
    }
    if (this.state.contentId && this.state.isSaved) {
      api
        .delete(
          "/activity/delete?activity_type=SA&activity_on=Content_" +
            this.state.contentId,
          { headers: { Authorization: "Token " + this.props.token } }
        )
        .then((res) => {
          // console.log(res.data + 'UnSave');
          this.setState({ isSaved: false });
        });
    }
  };
  AddViewHandler = () => {
    let Headers = {};
    if (this.props.token) {
      Headers = { Authorization: "Token " + this.props.token };
    }
    if (this.state.contentId) {
      api
        .post(
          "/activity/addview",
          {
            activity_type: "VI",
            activity_on: `Content_${this.state.contentId}`,
          },
          { headers: Headers }
        )
        .then((res) => {
          // console.log(res.data);
        });
    }
  };
  SendCommentHandler = (newComment) => {
    // console.log('Sending Comment' , newComment);
    api
      .post(
        "/comment/add",
        {
          text: newComment,
          author: this.props.token,
          commented_on: "Content_" + this.state.contentId,
        },
        { headers: { Authorization: "Token " + this.props.token } }
      )
      .then((res) => {
        this.setState((prevState) => {
          if (Array.isArray(prevState.comments)) {
            return {
              comments: [res.data, ...prevState.comments],
            };
          } else {
            return { comments: [res.data] };
          }
        });
      });
  };
  componentDidMount() {
    this.Start();
  }
  componentDidUpdate(prevProps) {
    if (this.props.match.params !== prevProps.match.params) {
      this.setState({
        video: null,
        title: null,
        article: null,
        quiz: null,
        comments: null,
        description: null,
        views: null,
        likes: null,
        tags: null,
        videoId: null,
        quizId: null,
        cover: null,
        contentId: null,
        isLiked: false,
        isSaved: false,
        relatedFile: null,
        isDataFeched: false,
      });
      this.Start();
    }
    if (
      this.props.match.params.ModuleOrder !== prevProps.match.params.ModuleOrder
    ) {
      this.setState({ Module: null, NextModule: null, PrevModule: null });
    }
  }
  getModuleContentLength = (a) => {
    this.setState({ ModuleContentLength: a });
  };
  NextCourse = () => {
    const CoursePathArray = this.props.location.pathname.split("/");
    const CourseIndex = +CoursePathArray.pop();
    if (CourseIndex + 1 < this.state.ModuleContentLength) {
      const NewIndex = CourseIndex + 1;
      const NewPath = CoursePathArray.join("/") + "/" + NewIndex.toString();
      this.props.history.push(NewPath);
    }
  };
  PrevCourse = () => {
    const CoursePathArray = this.props.location.pathname.split("/");
    const CourseIndex = +CoursePathArray.pop();
    if (CourseIndex >= 1) {
      const NewIndex = CourseIndex - 1;
      const NewPath = CoursePathArray.join("/") + "/" + NewIndex.toString();
      this.props.history.push(NewPath);
    }
  };
  render() {
    let coursemodule = (
      <div
        className={[styles.CourseModule, styles.CourseModuleLoading].join(" ")}
      >
        <CourseModule Loading VideoPage />
      </div>
    );
    if (this.state.Module) {
      coursemodule = (
        <div className={styles.CourseModule}>
          {/* <h1 className={styles.CourseModuleTitle}>{this.state.Module.title}</h1> */}
          <div className={styles.CourseModuleViewPort}>
            <CourseModule
              Title={this.state.Module.title}
              ModuleContentLengthSetter={this.getModuleContentLength}
              NextModule={this.state.NextModule}
              id={this.state.Module.id}
              PrevModule={this.state.PrevModule}
              CoursePath={this.props.match.params.CoursePath}
              ModuleOrder={this.props.match.params.ModuleOrder}
              VideoPage
            />
          </div>
        </div>
      );
    }
    let CourseDetail = (
      <div className={styles.CourseDetail}>
        <div
          className={[styles.CourseDetailImage, styles.Loading].join(" ")}
        ></div>
        <div>
          <div className={[styles.CourseTitle, styles.Loading].join(" ")}></div>
          <span
            className={[styles.CourseParts, styles.Loading].join(" ")}
          ></span>
          <span
            className={[styles.CourseTime, styles.Loading].join(" ")}
          ></span>
        </div>
      </div>
    );
    if (this.state.courseTitle) {
      CourseDetail = (
        <Link
          to={"/Course/" + this.props.match.params.CoursePath}
          className={styles.CourseDetail}
        >
          <img
            className={styles.CourseDetailImage}
            src={this.state.courseImage}
            alt={this.state.courseTitle}
          />
          <div>
            <div className={styles.CourseTitle}>{this.state.courseTitle}</div>
            <span className={styles.CourseParts}>
              {this.state.courseParts.toString().toPersinaDigit()}
              <span> قسمت </span>
            </span>
            <span className={styles.CourseTime}>
              {TimeSeter(this.state.courseTime)}
            </span>
          </div>
        </Link>
      );
    }

    const CourseDetailAndDownLoadFiles = (
      <>
        <div className={styles.ButtonsAndCourseDetailContainer}>
          {CourseDetail}
          <FileDownloadButton
            Loading={!this.state.isDataFeched}
            FileName={this.state.title}
            FileLink={this.state.relatedFile}
          >
            دانلود فایل‌های آموزشی
          </FileDownloadButton>
          <div className={styles.CourseControl}>
            <div onClick={this.NextCourse} className={styles.NextCourse}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 492.004 492.004"
                xmlSpace="preserve"
              >
                <g>
                  <g>
                    <path d="M484.14,226.886L306.46,49.202c-5.072-5.072-11.832-7.856-19.04-7.856c-7.216,0-13.972,2.788-19.044,7.856l-16.132,16.136    c-5.068,5.064-7.86,11.828-7.86,19.04c0,7.208,2.792,14.2,7.86,19.264L355.9,207.526H26.58C11.732,207.526,0,219.15,0,234.002    v22.812c0,14.852,11.732,27.648,26.58,27.648h330.496L252.248,388.926c-5.068,5.072-7.86,11.652-7.86,18.864    c0,7.204,2.792,13.88,7.86,18.948l16.132,16.084c5.072,5.072,11.828,7.836,19.044,7.836c7.208,0,13.968-2.8,19.04-7.872    l177.68-177.68c5.084-5.088,7.88-11.88,7.86-19.1C492.02,238.762,489.228,231.966,484.14,226.886z" />
                  </g>
                </g>
              </svg>
            </div>
            <div onClick={this.PrevCourse} className={styles.PrevCourse}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 492.004 492.004"
                xmlSpace="preserve"
              >
                <g>
                  <g>
                    <path d="M484.14,226.886L306.46,49.202c-5.072-5.072-11.832-7.856-19.04-7.856c-7.216,0-13.972,2.788-19.044,7.856l-16.132,16.136    c-5.068,5.064-7.86,11.828-7.86,19.04c0,7.208,2.792,14.2,7.86,19.264L355.9,207.526H26.58C11.732,207.526,0,219.15,0,234.002    v22.812c0,14.852,11.732,27.648,26.58,27.648h330.496L252.248,388.926c-5.068,5.072-7.86,11.652-7.86,18.864    c0,7.204,2.792,13.88,7.86,18.948l16.132,16.084c5.072,5.072,11.828,7.836,19.044,7.836c7.208,0,13.968-2.8,19.04-7.872    l177.68-177.68c5.084-5.088,7.88-11.88,7.86-19.1C492.02,238.762,489.228,231.966,484.14,226.886z" />
                  </g>
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
            </div>
          </div>
        </div>
      </>
    );
    let MetaTitle = "براکت آکادمی -" + this.state.title ? this.state.title : "";
    let MetaDescription =
      "براکت آکادمی - " + this.state.description ? this.state.description : "";
    let MetaVideoUrl = this.state.video ? this.state.video : "";
    let MetaCoverImage = this.state.cover ? this.state.cover : "";
    return (
      <>
        <Helmet>
          <title>{MetaTitle}</title>
          <meta name="description" content={MetaDescription} />
          <meta name="title" content={MetaTitle} />
          <meta property="og:type" content="video" />
          <meta property="og:title" content={MetaTitle} />
          <meta property="og:description" content={MetaDescription} />
          {/* Video Meta Tags */}
          <meta property="og:video:url" content={MetaVideoUrl} />
          <meta property="og:video:secure_url" content={MetaVideoUrl} />
          <meta property="og:video:type" content="video/mp4" />
          <meta property="og:video:width" content="640" />
          <meta property="og:video:height" content="360" />
          {/* Image Meta Tags */}
          <meta property="og:image" content={MetaCoverImage} />
          <meta property="og:image:secure_url" content={MetaCoverImage} />
          <meta property="og:image:width" content="640" />
          <meta property="og:image:height" content="360" />
          <meta property="og:image:type" content="image/jpg" />
        </Helmet>
        <div className={styles.ContentPage}>
          <div className={styles.MainPart}>
            <Route path="/Content/Video">
              <VideoContent
                contentPath={
                  this.props.match.params.CoursePath +
                  "/" +
                  this.props.match.params.ModuleOrder +
                  "/" +
                  this.props.match.params.ContentOrder
                }
                article={this.state.article}
                video={this.state.video}
                cover={this.state.cover}
                title={this.state.title}
                views={this.state.views}
                likes={this.state.likes}
                isLiked={this.state.isLiked}
                isSaved={this.state.isSaved}
                description={this.state.description}
                LikeHandler={this.LikeHandler}
                SaveHandler={this.SaveHandler}
                AddViewHandler={this.AddViewHandler}
                CourseDetailAndDownLoadFiles={CourseDetailAndDownLoadFiles}
              />
            </Route>
            <Route path="/Content/Article">
              <ArticleContent
                contentPath={
                  this.props.match.params.CoursePath +
                  "/" +
                  this.props.match.params.ModuleOrder +
                  "/" +
                  this.props.match.params.ContentOrder
                }
                article={this.state.article}
                video={this.state.video}
                cover={this.state.cover}
                title={this.state.title}
                views={this.state.views}
                likes={this.state.likes}
                isLiked={this.state.isLiked}
                isSaved={this.state.isSaved}
                description={this.state.description}
                LikeHandler={this.LikeHandler}
                SaveHandler={this.SaveHandler}
                AddViewHandler={this.AddViewHandler}
                CourseDetail={CourseDetail}
              />
            </Route>
            <Route path="/Content/Quiz">
              <QuizContent
                quizId={this.state.quizId}
                quiz={this.state.quiz}
                contentPath={
                  this.props.match.params.CoursePath +
                  "/" +
                  this.props.match.params.ModuleOrder +
                  "/" +
                  this.props.match.params.ContentOrder
                }
                cover={this.state.cover}
                title={this.state.title}
                views={this.state.views}
                likes={this.state.likes}
                isLiked={this.state.isLiked}
                isSaved={this.state.isSaved}
                description={this.state.description}
                LikeHandler={this.LikeHandler}
                SaveHandler={this.SaveHandler}
                AddViewHandler={this.AddViewHandler}
                CourseDetail={CourseDetail}
              />
            </Route>
            <div className={styles.CommentsContainer}>
              <div className={styles.CourseModuleSmallScreen}>
                {coursemodule}
              </div>
              <Tags TagsList={this.state.tags} />

              <Comments
                SendComment={(newComment) => {
                  this.SendCommentHandler(newComment);
                }}
                Comments={this.state.comments}
              />
            </div>
          </div>
          <div className={styles.CourseModuleBigScreen}>{coursemodule}</div>
        </div>

        {this.state.ShowSaveSuccesMessage ? (
          <SuccessMessage OnClear={this.ClearSaveSuccesMessage}>
            محتوا با موفقیت ذخیره شد.
          </SuccessMessage>
        ) : null}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};
export default connect(mapStateToProps)(ContentPage);
