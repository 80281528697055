import React from 'react';
import styles from './AppsList.module.css';
import AppsListItem from './AppsListItem/AppsListItem'

const AppsList = (props) => {
    
    return (
        <div
        onMouseLeave={props.CloseList} 
        className={styles.AppsList}>
            <div className={styles.AppsListViewPort}>
              <AppsListItem title="آکادمی" text="Academy" color="255, 127, 80" />
              <AppsListItem title="ادیتور" text="Editor" color="109,207,246"  Link="https://bracketeditor.ir" />
              <AppsListItem title="اپلیکیشن" text = "App" color="163,211,156"  />
              <AppsListItem title="بلاگ" text="Blog" color="244,154,193"/>
              
            </div>
        </div>
    )
}

export default AppsList;