import Tag from "./Tag/Tag";
import styles from './Tags.module.css';

const Tags = (props)=>{
    const loadingTagsCount = new Array(20);
    loadingTagsCount.fill(1);
    let tags = <>
    {loadingTagsCount.map((val , inx) => {return <Tag loading key={inx}></Tag>})}
    </>;
    if (props.TagsList) {
        tags = props.TagsList.map(tag => <Tag>{tag}</Tag>);
        if ([...props.TagsList].length === 0) {
            tags = <span>تگی وجود ندارد.</span>
        }
    } 
    
    return <div className={styles.Tags}>
        <h3 className={styles.Title}>تگ‌ها</h3>
        <div className={styles.TagsContainer}>
        {tags}
        </div>
        </div> 
}

export default Tags;