 const TimeSeter = (time)=>{
    let Time = 'بدون زمان';
    
     const CourseTimeArray = time.split(':');
     const CourseTimeHours = Math.floor(+CourseTimeArray[0]) ;
     const CourseTimeMinutes = Math.floor(+CourseTimeArray[1]) ;
     const CourseTimeSecends = Math.floor(+CourseTimeArray[2]) ;
     if (CourseTimeHours > 0) {
         Time = CourseTimeHours.toString().toPersinaDigit() + ' ساعت ';
        return Time;
     }
     else {
         if (CourseTimeMinutes > 0) {
             Time = CourseTimeMinutes.toString().toPersinaDigit() + ' دقیقه ';
        return Time;
            
         }
         else {
             if (CourseTimeSecends >= 0) {
                 Time = CourseTimeSecends.toString().toPersinaDigit() + ' ثانیه ';
        return Time;
                 
             }
             
         }
         
     
     
    }
}
export default TimeSeter;