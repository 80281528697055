import React from 'react';
import styles from './NotificationsListItem.module.css';
import {Link} from 'react-router-dom'
const NotificationsListItem = (props) => {
    return (
        
        <Link to={props.Link} className={styles.NotificationsListItem}>
        <div className={styles.Notification}>
        <span className={styles.Title}>اعلان</span>
        <span>{props.children}</span>
        </div>
        <span className={styles.New}>جدید</span>
          
        </Link>
    )
}

export default NotificationsListItem;