import React from 'react';
import styles from './Paragraph.module.css';
const Paragraph = (props)=>{
    return (
    <>
   { props.children ? <p className={[styles.Paragraph , props.className].join(' ')} 
   dangerouslySetInnerHTML={ props.hasHTMLElement ? {__html : props.children} : null}>
     {props.hasHTMLElement ? null : props.children}
   </p>
    : <div className={styles.Paragraph}> 
    <div className={[styles.Loading , styles.First].join(' ')}></div>
    <div className={styles.Loading}></div>
    <div className={styles.Loading}></div>
    <div className={styles.Loading}></div>
    
     </div>
   }
    </>
    )
}
export default Paragraph;