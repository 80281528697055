import React from 'react';
import styles from './PlayingIcon.module.css';
const playingIcon = (props)=> {
    let ClassName = null;
    switch (props.Type) {
        case 'Article' :
            ClassName = styles.Text;
            break;
        case 'Video' :
            ClassName = styles.Video;
            break;
        case 'Quiz':
            ClassName = styles.Quiz;
            break;
        default :
        ClassName = styles.Content;
        break;

    }
    return (
        <div className={styles.PlayingIcon}>
            <div className={[styles.Box , ClassName , styles.First].join(' ')}></div>
            <div className={[styles.Box , ClassName , styles.Second].join(' ')}></div>
            <div className={[styles.Box , ClassName , styles.Third].join(' ')}></div>
            <div className={[styles.Box , ClassName , styles.Second].join(' ')}></div>
            <div className={[styles.Box, ClassName , styles.Third].join(' ')}></div>
            
        </div>
    )
}

export default playingIcon;