import React from 'react';
import styles from './VideoItemSkeleton.module.css';
const VideoItemSkeleton = (props)=>{
    let skeletons = [];
    for (let index = 0; index < 20; index++) {
         skeletons = [...skeletons , index]
        
    }
    return (
        <>
        {skeletons.map((el)=> 
        <div key={el} 
        className={[styles.VideoItemSkeleton 
        , styles.Loading].join(' ')}>
        </div>)}
        
        </>
    )
}
export default VideoItemSkeleton;