import React, { useState } from "react";
import styles from "./AppsListItem.module.css";
const AppsListItem = (props) => {
  const [hover, setHover] = useState(false);
  const isHover = ()=>{
    setHover(true)
  }
  const unHover = () =>{
    setHover(false);
  }
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={props.Link}
      className={styles.AppsListItem}
      onMouseEnter={isHover}
      onMouseLeave={unHover}
      style={{backgroundColor : hover || props.active ? `rgba(${props.color} , 0.05) ` : 'white'}}
    >
      {props.text ? <h2  className={styles.Text}>
        <span style={{backgroundColor : `rgba(${props.color} , 0.75) `}} className={styles.Dot}></span>
        <span>{props.text}</span> 
        </h2> : null}
      {props.image ? <img className={styles.image} src={props.image} alt={props.title} /> : null}
      <span 
      style={{color : hover || props.active ? `rgba(${props.color} , 0.5) ` : 'unset'}}
      className={styles.Title}>{props.title}</span>
    </a>
  );
};

export default AppsListItem;
