import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import * as actions from '../../store/actions/action';

class RedirectPage extends Component {
    state = {
        redirectPath : null,
    }
    componentDidMount(){
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('token')
        this.props.onSignIn(token);
        this.props.onGetUserData(token);
        localStorage.setItem('token' , token);
        let RedirectPath = localStorage.getItem('RedirectPath');
        localStorage.removeItem('RedirectPath');
        this.setState({
            redirectPath : RedirectPath
        })
    }
    render (){
      
    

        const query = new URLSearchParams(this.props.location.search);
        return (
            <>
            {this.props.token 
            && localStorage.getItem('token')
            && !localStorage.getItem('RedirectPath') ? <Redirect to={this.state.redirectPath} /> : null}
            <h1>Redirecting ...</h1>
            <h1>
                {query.get('token')}
            </h1>
            </>
        )
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onSignIn : (token)=> dispatch(actions.SignInSuccess(token)),
        onGetUserData : (token)=> dispatch(actions.StartGetUserData(token)),
    
      }
    }
const mapStateToProps = state => {
        return {
          token : state.token
        }
      }
export default connect(mapStateToProps , mapDispatchToProps)(RedirectPage);