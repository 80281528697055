import React from 'react';
import { Link , withRouter } from 'react-router-dom';
import SaveButton from '../Buttons/SaveButton/SaveButton';
import styles from './ArticleItem.module.css';
const ArticleItem = (props)=>{

    return (
        <div className={styles.ArticleItem}>

            <Link to={'/' + props.articleLink} className={styles.CoverContainer}>
            <img 
            src={props.cover} 
            alt={props.title + '-' + props.topic}
            className={styles.Cover} />
             {/* <div className={styles.DurationContainer}>
                <h3 className={styles.Duration}>{props.duration.toString().toPersinaDigit()}</h3>
            </div> */}
            </Link>

            <div className={styles.Details}>
            <Link to={'/' + props.articleLink} className={styles.Title}>{props.title}</Link>
            
            <div className={styles.DescriptionContainer}>
                <p>{props.description}</p>
            </div>
            
            <div className={styles.Controls}>
            
                <div className={styles.TitlesContainer}>
                <Link to={'/Course/' + props.topicLink} className={styles.CourseImageContainer}>
                <img src={props.courseImage} className={styles.CourseImage} alt={props.topic}  />
                </Link>
                    <Link 
                    className={styles.Topic}
                    to={'/Course/' + props.topicLink}>
                        {props.topic}</Link>
                </div>
                
              {props.SavedVideo ? <SaveButton isSavedItem isSaved SaveHandler = {props.unSaveHandler}/> : null}
            </div>
            </div>
        </div>
    )
}

export default withRouter(ArticleItem);