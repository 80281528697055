import React from "react";
import HeroSlider from "../../Components/Sliders/HeroSlider/HeroSlider";
import Slider from "../../Components/Sliders/Slider/Slider";
import styles from "./Home.module.css";
import { Helmet } from "react-helmet";
import Topics from "../../Components/Topics/Topics";
import VideoSlider from "../../Components/Sliders/VideoSlider/VideoSlider";
import ArticleSlider from "../../Components/Sliders/ArticleSlider/ArticleSlider";
import api from "../../api/api";
import Particles from "react-particles";
import {loadSeaAnemonePreset} from 'tsparticles-preset-sea-anemone';
class Home extends React.Component {
  state = {
    recentCourses: null,
    mostLikeCourses: null,
    mostLikeVideos: null,
    recentVideos: null,
    mostLikeArticles: null,
    recentArticles: null,
    searchValue: "",
  };
  Searching = () => {
    if (this.state.searchValue.trim() !== "") {
      this.props.history.push("/Search?SearchValue=" + this.state.searchValue);
    }
  };
  componentDidMount() {

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    //MostLiked Courses
    api.get("/course/?sort=LI&count=5").then((res) => {
      this.setState({ mostLikeCourses: [...res.data.results] });
    });

    //Recent Courses
    api.get("/course/?count=5").then((res) => {
      // console.log(res.data.results)
      this.setState({ recentCourses: [...res.data.results] });
    });

    //MostLiked Videos
    api.get("/content/top?type=video&&sort=top").then((res) => {
      this.setState({ mostLikeVideos: [...res.data] });
    });
    //Recent Videos
    api.get("/content/top?type=video&&sort=date").then((res) => {
      this.setState({ recentVideos: [...res.data] });
    });

    //MostLiked Articles
    api.get("/content/top?type=text&&sort=top").then((res) => {
      this.setState({ mostLikeArticles: [...res.data] });
    });
    //Recent Articles
    api.get("/content/top?type=text&&sort=date").then((res) => {
      this.setState({ recentArticles: [...res.data] });
    });
  }
  async InitParticle(engine){
    // this adds the preset to tsParticles, you can safely use the
    await loadSeaAnemonePreset(engine);
  }
  render() {
    return (
      <div className={styles.Home}>
        <Helmet>
          <title>براکت آکادمی</title>
          <meta
            name="description"
            content="براکت آکادمی - محلی برای پرورش استعدادهای شماست!
           با استفاده
           از آموزش رایگان‌های
           براکت آکادمی می‌توانید
           استعداد‌های خود را پرورش دهید!"
          />
          <meta property="og:type" content="home" />
          <meta property="og:title" content="براکت آکادمی - خانه" />
          <meta property="og:description" content="براکت آکادمی - خانه" />
        </Helmet>
        <div className={styles.Top}>
          <Particles className={styles.Particles} options={{preset : 'seaAnemone' , fullScreen : false}} init={this.InitParticle} />
          <div className={styles.TopData}>
            <h1>استعدادت رو پیدا کن...</h1>
            <p>
              براکت آکادمی، توی حوزه‌های مختلف آموزش داره و تلاش می‌کنه تا برای
              هر استعدادی آموزش تولید کنه.
            </p>
            <div className={styles.InputContainer}>
              <svg
                onClick={() => {
                  this.Searching();
                }}
                className={styles.Icon}
                id="Search"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <rect
                  id="Rectangle_693"
                  data-name="Rectangle 693"
                  width="16"
                  height="16"
                  fill="none"
                />
                <path
                  id="Path_208"
                  data-name="Path 208"
                  d="M15.9,14.5l-3.3-3.3A6.847,6.847,0,0,0,14,7,6.957,6.957,0,0,0,7,0,6.957,6.957,0,0,0,0,7a6.957,6.957,0,0,0,7,7,6.847,6.847,0,0,0,4.2-1.4l3.3,3.3ZM2,7A4.951,4.951,0,0,1,7,2a4.951,4.951,0,0,1,5,5,4.951,4.951,0,0,1-5,5A4.951,4.951,0,0,1,2,7Z"
                />
              </svg>
              <input
                // value={this.state.searchValue}
                onBlur={(e) => {
                  this.setState({ searchValue: e.target.value });
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    this.Searching();
                  }
                }}
                placeholder="جست و جو ..."
                className={styles.SearchInput}
              />
            </div>
          </div>
        </div>

        <Topics />

        <div className={styles.Line}></div>

        <Slider
          PathPrefix={"/Course/"}
          title="محبوب‌‌‌ترین‌ آموزش‌ها"
          slides={this.state.mostLikeCourses}
        />
        <div className={styles.Line}></div>
        <Slider
          PathPrefix={"/Course/"}
          title="جدیدترین آموزش‌ها"
          slides={this.state.recentCourses}
        />

        <div className={styles.Line}></div>

        <VideoSlider
          title="محبوب‌‌‌ترین‌ ویدیوها"
          slides={this.state.mostLikeVideos}
        />

        <div className={styles.Line}></div>

        <VideoSlider
          title="جدیدترین ویدیوها"
          slides={this.state.recentVideos}
        />
        <div className={styles.Line}></div>

        <ArticleSlider
          title="محبوب‌‌‌ترین‌ مقاله‌ها"
          slides={this.state.mostLikeArticles}
        />
        <div className={styles.Line}></div>

        <ArticleSlider
          title="جدیدترین مقاله‌ها"
          slides={this.state.recentArticles}
        />

        <div className={styles.Line}></div>
        <div className={styles.BlogAndAbout}>
          <div
            className={styles.Blog}
            onClick={(event) => {
              this.props.history.push("/Blog");
            }}
          >
            
            <div className={styles.BlogButton}>
              <h1>بلاگ براکت</h1>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 492.004 492.004"
                xmlSpace="preserve"
              >
                <g>
                  <g>
                    <path d="M484.14,226.886L306.46,49.202c-5.072-5.072-11.832-7.856-19.04-7.856c-7.216,0-13.972,2.788-19.044,7.856l-16.132,16.136    c-5.068,5.064-7.86,11.828-7.86,19.04c0,7.208,2.792,14.2,7.86,19.264L355.9,207.526H26.58C11.732,207.526,0,219.15,0,234.002    v22.812c0,14.852,11.732,27.648,26.58,27.648h330.496L252.248,388.926c-5.068,5.072-7.86,11.652-7.86,18.864    c0,7.204,2.792,13.88,7.86,18.948l16.132,16.084c5.072,5.072,11.828,7.836,19.044,7.836c7.208,0,13.968-2.8,19.04-7.872    l177.68-177.68c5.084-5.088,7.88-11.88,7.86-19.1C492.02,238.762,489.228,231.966,484.14,226.886z" />
                  </g>
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
            </div>
            <svg className={styles.BlogImage}  
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 22.847 33.82">
              <path id="Path_215" d="M95.015,220.108a.879.879,0,0,1-1.243,0l-1.136-1.136L90,221.608v.515h12.3v-.515l-4.394-4.394Zm0,0" transform="translate(-84.727 -202.549)"></path><path id="Path_216" d="M90,156.787l2.015-2.015a.878.878,0,0,1,1.243,0l1.136,1.136,2.894-2.893a.878.878,0,0,1,1.243,0l3.772,3.772V151H90Zm0,0" transform="translate(-84.727 -140.756)"></path><path id="Path_217" d="M21.969,0H.879A.94.94,0,0,0,0,.991V32.829a.94.94,0,0,0,.879.991h21.09a.94.94,0,0,0,.879-.991V.991A.94.94,0,0,0,21.969,0ZM9.666,29.79H4.394a1,1,0,0,1,0-1.982H9.666a1,1,0,0,1,0,1.982Zm0-3.963H4.394a1,1,0,0,1,0-1.982H9.666a1,1,0,0,1,0,1.982Zm8.787,3.963H13.181a1,1,0,0,1,0-1.982h5.272a1,1,0,0,1,0,1.982Zm0-3.963H13.181a1,1,0,0,1,0-1.982h5.272a1,1,0,0,1,0,1.982Zm.879-4.954a.94.94,0,0,1-.879.991H4.394a.94.94,0,0,1-.879-.991V8.983a.94.94,0,0,1,.879-.991h14.06a.94.94,0,0,1,.879.991ZM18.454,6.011H4.394a.94.94,0,0,1-.879-.991.94.94,0,0,1,.879-.991h14.06a.94.94,0,0,1,.879.991A.94.94,0,0,1,18.454,6.011Zm0,0">
              </path></svg>
          </div>
          <div
            className={styles.About}
            onClick={(event) => {
              this.props.history.push("/About");
            }}
          >
            <svg className={styles.AboutImage} version="1.1"
              viewBox="0 0 502.64 502.64"
              xmlSpace="preserve"
              xmlns="http://www.w3.org/2000/svg">
              <path d="m251.26 237.59c37.166 0 67.042-30.048 67.042-66.977 0.043-37.037-29.876-66.999-67.042-66.999-36.908 0-66.869 29.962-66.869 66.999 0 36.973 29.962 66.977 66.869 66.977z" />
              <path d="m305.03 248.51h-107.38c-19.198 0-34.923 17.602-34.923 39.194v107.85c0 1.186 0.604 2.243 0.669 3.473h175.82c0.129-1.229 0.626-2.286 0.626-3.473v-107.85c0.064-21.592-15.661-39.194-34.816-39.194z" />
              <path d="m431.59 269.56c29.832 0 53.754-24.008 53.754-53.668s-23.922-53.711-53.754-53.711c-29.617 0-53.582 24.051-53.582 53.711-0.064 29.639 23.966 53.668 53.582 53.668z" />
              <path d="m474.71 278.32h-86.046c-15.445 0-28.064 14.107-28.064 31.472v86.413c0 0.928 0.453 1.812 0.518 2.826h141.03c0.065-1.014 0.496-1.898 0.496-2.826v-86.413c0.065-17.365-12.532-31.472-27.934-31.472z" />
              <path d="m71.011 269.56c29.789 0 53.733-24.008 53.733-53.668s-23.944-53.711-53.733-53.711c-29.638 0-53.603 24.051-53.603 53.711s23.965 53.668 53.603 53.668z" />
              <path d="m114.11 278.32h-86.132c-15.401 0-27.977 14.107-27.977 31.472v86.413c0 0.928 0.453 1.812 0.539 2.826h141.03c0.065-1.014 0.475-1.898 0.475-2.826v-86.413c0.043-17.365-12.555-31.472-27.935-31.472z" />
            </svg>
            <div className={styles.AboutButton}>
              <h1>درباره ما</h1>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 492.004 492.004"
                xmlSpace="preserve"
              >
                <g>
                  <g>
                    <path d="M484.14,226.886L306.46,49.202c-5.072-5.072-11.832-7.856-19.04-7.856c-7.216,0-13.972,2.788-19.044,7.856l-16.132,16.136    c-5.068,5.064-7.86,11.828-7.86,19.04c0,7.208,2.792,14.2,7.86,19.264L355.9,207.526H26.58C11.732,207.526,0,219.15,0,234.002    v22.812c0,14.852,11.732,27.648,26.58,27.648h330.496L252.248,388.926c-5.068,5.072-7.86,11.652-7.86,18.864    c0,7.204,2.792,13.88,7.86,18.948l16.132,16.084c5.072,5.072,11.828,7.836,19.044,7.836c7.208,0,13.968-2.8,19.04-7.872    l177.68-177.68c5.084-5.088,7.88-11.88,7.86-19.1C492.02,238.762,489.228,231.966,484.14,226.886z" />
                  </g>
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
