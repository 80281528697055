import React, { useEffect } from 'react';
import styles from './Notfound.module.css';
import SVG from './svg/NotFound.svg';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';

const Notfound = (props)=>{
    useEffect(()=>{
        window.scrollTo({top:0 , left:0 , behavior:'smooth'})
    })
    return (
        <>
         <Helmet>
            <title>براکت آکادمی - پیدا نشد</title>
            <meta name="description" content="براکت آکادمی - پیدا نشد" />
        </Helmet>
        <div className={styles.Notfound}>
        <img className={styles.SVG} src={SVG} alt="پیدا نشد!" />
        <h1 className={styles.Title}>صفحه پیدا نشد!</h1>
        <Link to="/" className={styles.Link}>بازگشت به صفحه اصلی</Link>
        </div>
        
        </>
    )
} 

export default Notfound;