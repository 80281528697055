import React, { useEffect, useState } from "react";
import styles from "./CourseTopic.module.css";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import TimeSeter from "../Utilities/TimeSeter";
// eslint-disable-next-line no-extend-native
String.prototype.toPersinaDigit = function () {
  var id = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  return this.replace(/[0-9]/g, function (w) {
    return id[+w];
  });
};
const CourseTopic = (props) => {
const [fullProgress , setFullProgress] = useState(false);
const [progress , setProgress] = useState(0);
  let CourseTime = "بدون زمان";
  if (props.CourseTime) {
    CourseTime = TimeSeter(props.CourseTime);
  }

  
    useEffect(()=>{
      if (props.Progress) {
        setTimeout(()=>{setProgress(props.Progress)} , 1)
    }
      if (props.Progress === 100) {
        
        setTimeout(()=>{
            setFullProgress(true);
        } , 450)
    } 
      else {
        setFullProgress(false);
      }
    } , [props.Progress])
    
    
  return (
    <div
      className={styles.CourseTopic}
      onClick={() => {
        props.history.push(props.Path);
      }}
    >
      <div className={styles.ImageContainer}>
        <img src={props.Src} className={styles.Image} alt={props.Title} />
      </div>
      <h1 className={styles.Title}>{props.Title}</h1>
      <div className={styles.DetailsContainer}>
        <div className={styles.CourseCountContainer}>
        <div className={styles.IconContainer}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="16"
            height="14"
            viewBox="0 0 16 14"
          >
            <defs>
              <clipPath id="clip-path">
                <rect width="16" height="14" fill="none" />
              </clipPath>
            </defs>
            <g id="Menu" clipPath="url(#clip-path)">
              <path
                id="Menu-2"
                d="M0,14V12H10v2ZM0,8V6H16V8ZM0,2V0H16V2Z"
                fill="#FF7F50"
              />
            </g>
          </svg>
          </div>
          <h6 className={styles.CourseCount}>
            {props.CourseCount.toPersinaDigit()} قسمت
          </h6>
        </div>
        <div className={styles.CourseTimeContainer}>
        <div className={styles.IconContainer}>
          <svg
            id="clock"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <g id="Group_47" transform="translate(7.381 3.712)">
              <g id="Group_46">
                <path
                  id="Path_209"
                  d="M239.639,124.48l-2.231-1.673V119.4a.62.62,0,0,0-1.239,0v3.718a.619.619,0,0,0,.248.5l2.479,1.859a.62.62,0,0,0,.744-.992Z"
                  transform="translate(-236.169 -118.779)"
                  fill="#FF7F50"
                />
              </g>
            </g>
            <g id="Group_49">
              <g id="Group_48">
                <path
                  id="Path_210"
                  d="M8,0a8,8,0,1,0,8,8A8.009,8.009,0,0,0,8,0ZM8,14.761A6.761,6.761,0,1,1,14.761,8,6.769,6.769,0,0,1,8,14.761Z"
                  fill="#FF7F50"
                />
              </g>
            </g>
          </svg>
          </div>
          <h6 className={styles.CourseTime}>{CourseTime}</h6>
        </div>
      </div>
      <div className={styles.Line}></div>
      <div className={styles.ButtonAndProgressContainer}>
        {props.token && props.Progress ? (
          <div className={styles.ProgressContainer}>{fullProgress ? 
        <div className={styles.FullProgress}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="Capa_1"
          viewBox="0 0 515.556 515.556"
        >
          <path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z" />
        </svg></div> : <CircularProgressbar
        value={progress}
        text={`${props.Progress.toString().toPersinaDigit()}%`}
        maxValue={100}
        minValue={0}
        strokeWidth={10}
        styles={{
          path: {
            stroke: `rgba(255, 127, 80)`,
          },
          trail: {
            // Trail color
            stroke: "rgba(0, 0, 0 , 0.05)",
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: "butt",
            // Rotate the trail
            transform: "rotate(0.25turn)",
            transformOrigin: "center center",
            transition : 'all 0.3s ease'
          },
          text: {
            // Text color
            fill: "rgba(0, 0, 0 , 0.75)",
            // Text size
            fontSize: "2rem",
          },
        }}
      />}
        </div>
        ) : null}

        {props.token && props.Progress ? (
          <div className={styles.ContinueButtonContainer}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              version="1.1"
              id="Layer_1"
              x="0px"
              y="0px"
              viewBox="0 0 492.004 492.004"
              xmlSpace="preserve"
            >
              <g>
                <g>
                  <path d="M484.14,226.886L306.46,49.202c-5.072-5.072-11.832-7.856-19.04-7.856c-7.216,0-13.972,2.788-19.044,7.856l-16.132,16.136    c-5.068,5.064-7.86,11.828-7.86,19.04c0,7.208,2.792,14.2,7.86,19.264L355.9,207.526H26.58C11.732,207.526,0,219.15,0,234.002    v22.812c0,14.852,11.732,27.648,26.58,27.648h330.496L252.248,388.926c-5.068,5.072-7.86,11.652-7.86,18.864    c0,7.204,2.792,13.88,7.86,18.948l16.132,16.084c5.072,5.072,11.828,7.836,19.044,7.836c7.208,0,13.968-2.8,19.04-7.872    l177.68-177.68c5.084-5.088,7.88-11.88,7.86-19.1C492.02,238.762,489.228,231.966,484.14,226.886z" />
                </g>
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
          </div>
        ) : (
          <div className={styles.ContinueButton}>ادامه</div>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};
export default connect(mapStateToProps)(withRouter(CourseTopic));
