import React from 'react';
import EmptyBox from '../../../EmptyBox/EmptyBox';
import styles from './NotificationsList.module.css';
import NotificationsListItem from './NotificationsListItem/NotificationsListItem';

const NotificationsList = (props) => {
    let Notifications = (<div className={styles.EmptyBox}>
       <EmptyBox />
        <h1>نوتیفیکیشن ندارید!</h1>
    </div>);
    if (props.Notifications.length > 0) {
        Notifications = (
            props.Notifications.map((notif , index)=>{
                return (
                    <>
                <NotificationsListItem key={Math.random()}>{notif}</NotificationsListItem>
                {index === props.Notifications.length - 1 ? null : <div className={styles.Line}>.</div>}
                    </>)
            })
        )
    }
    return (
        <div
        onMouseLeave={props.CloseList} 
        className={styles.NotificationsList}>
            <div className={styles.NotificationsListViewPort}>
            {
               Notifications
            }
            </div>
        </div>
    )
}

export default NotificationsList;