import React, { Component } from 'react';
import styles from './NotificationButton.module.css';
import {connect} from 'react-redux';
import NotificationsList from './NotificationsList/NotificationsList';
class NotificationButton extends Component {
    state = {
        showList : false,
        notifications : []
    }
    componentDidMount(){
        document.addEventListener('click' , (event)=>{
            if (this.Notifications && this.state.showList) {
                if (!this.Notifications.contains(event.target)) {
                    this.setState({showList : false})
                    // console.log(this.Notifications)
                } 
            }
            
        });
    }
    ToggleList = ()=> {
        this.setState((prevState)=>{
            return {
                showList : !prevState.showList
            }
        })
    }
    CloseList = ()=> {
        this.setState({showList : false});
    }
    render(){
        return (
            <>
            {this.props.token ?
                <>
                <div ref={(el)=>{
                    this.Notifications = el;
                }} onClick={this.ToggleList} className={[styles.NotificationButton , this.props.white ? styles.White : null].join(' ')}>
               {this.state.notifications.length > 0 ? <span className={styles.NotificationCount}>{'1'.toPersinaDigit()}</span> : null}
                <svg xmlns="http://www.w3.org/2000/svg" id="Layer_4"  viewBox="0 0 24 24" width="512"><g><path d="m21.379 16.913c-1.512-1.278-2.379-3.146-2.379-5.125v-2.788c0-3.519-2.614-6.432-6-6.92v-1.08c0-.553-.448-1-1-1s-1 .447-1 1v1.08c-3.387.488-6 3.401-6 6.92v2.788c0 1.979-.867 3.847-2.388 5.133-.389.333-.612.817-.612 1.329 0 .965.785 1.75 1.75 1.75h16.5c.965 0 1.75-.785 1.75-1.75 0-.512-.223-.996-.621-1.337z"/><path d="m12 24c1.811 0 3.326-1.291 3.674-3h-7.348c.348 1.709 1.863 3 3.674 3z"/></g></svg>
                {this.state.showList ? <NotificationsList 
                Notifications={this.state.notifications}
                CloseList={this.CloseList} /> : null}
                </div>

                </>
            :null}
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        token : state.token
    }
}
export default connect(mapStateToProps)(NotificationButton);