import React from 'react';
import styles from './GoUpButton.module.css';
const GoUpButton = (props)=>{
 
    return (
        <div className={styles.GoUpButtonContainer}>
       <div className={styles.GoUpButton} onClick={()=>{
           window.scrollTo({top:0 , left:0 , behavior:'smooth'})
       }}>
            <div className={styles.Arrow}>
                <div className={styles.RightArrow}></div>
                <div className={styles.LeftArrow}></div>
            </div>
        </div>
        </div>
    )
}

export default GoUpButton;