import { useState } from 'react';
import styles from './Choice.module.css';
const Choice = (props)=>{

    
    return (
        <div className={[
             styles.Choice,
             props.answer ? styles.answer : null , 
             props.loading ? styles.Loading : null].join(' ')}>
        <input 
        onChange={(el)=>{
            props.onSubmit(props.quastion , props.id);    
        }}
        disabled={props.isChecked} 
        type="radio" 
        value={props.value} 
        name={props.name}  />
        <label>{props.value ? props.value.toPersinaDigit() : null}</label>
        <p>{props.quastion}</p>
        </div>
    )
}

export default Choice;