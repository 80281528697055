import React from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
    EmailShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    
  } from "react-share";
  import {
    EmailIcon,
    LinkedinIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
  } from "../../../Icons/SocialMediaIcons/SocialMediaIcons";
import styles from './ShareButtonModal.module.css';
const ShareButtonModal = (props)=> {
const Pathname = window.location.href;
let Position = null;
switch (props.Position) {
    case 'Left':
        Position = styles.Left;
        break;
    case 'Center':
        Position = styles.Center;
        break;
    default:
        Position = styles.Right;
}
    return (
        
            <div onMouseLeave={props.MouseLeave} className={[styles.ShareButtonModal , Position].join(' ')}>
            <div className={styles.Top}>
            <div className={styles.ShareIconAndTitleContainer}>
            <span className={styles.Title}>اشتراک گذاری</span>
            <div className={styles.ShareIcon}>
            <svg className={styles.SVG} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 424.96 424.96" xmlSpace="preserve">
            <g>
                <g>
                    <path d="M340.48,300.587c-16.213,0-30.827,6.293-41.92,16.427l-152-88.64c1.173-4.8,1.92-9.813,1.92-14.933    s-0.747-10.133-1.92-14.933l150.4-87.787c11.413,10.667,26.667,17.28,43.52,17.28c35.307,0,64-28.693,64-64    c0-35.307-28.693-64-64-64c-35.307,0-64,28.693-64,64c0,5.12,0.747,10.133,1.92,14.933L128,166.72    c-11.413-10.667-26.667-17.28-43.52-17.28c-35.307,0-64,28.693-64,64c0,35.307,28.693,64,64,64c16.853,0,32.107-6.613,43.52-17.28    l152,88.64c-1.067,4.48-1.707,9.173-1.707,13.973c0,34.347,27.84,62.187,62.187,62.187s62.187-27.84,62.187-62.187    S374.827,300.587,340.48,300.587z"/>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            </svg>
            </div>
          
            </div>
            <CopyToClipboard text={Pathname}>
            <div onClick={()=>{

            }} className={styles.CopyToClipboard}>
            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="-40 0 512 512"><path d="m271 512h-191c-44.113281 0-80-35.886719-80-80v-271c0-44.113281 35.886719-80 80-80h191c44.113281 0 80 35.886719 80 80v271c0 44.113281-35.886719 80-80 80zm-191-391c-22.054688 0-40 17.945312-40 40v271c0 22.054688 17.945312 40 40 40h191c22.054688 0 40-17.945312 40-40v-271c0-22.054688-17.945312-40-40-40zm351 261v-302c0-44.113281-35.886719-80-80-80h-222c-11.046875 0-20 8.953125-20 20s8.953125 20 20 20h222c22.054688 0 40 17.945312 40 40v302c0 11.046875 8.953125 20 20 20s20-8.953125 20-20zm0 0"/></svg>
            <div className={styles.CopyToClipboardTooltip}><span>کپی لینک</span></div>

            </div>
            </CopyToClipboard>
            </div>
            <div className={styles.Line}></div>
            <div className={styles.ShareButtonsContainer}>
            <EmailShareButton className={styles.ShareButton}
            url={Pathname}>
                <EmailIcon />
            </EmailShareButton>

            <LinkedinShareButton className={styles.ShareButton}
            url={Pathname}>
                <LinkedinIcon>LinkedIn</LinkedinIcon>
            </LinkedinShareButton>

            <TelegramShareButton className={styles.ShareButton}
            url={Pathname}>
                <TelegramIcon >Telegram</TelegramIcon>
            </TelegramShareButton>

            <TwitterShareButton className={styles.ShareButton}
            url={Pathname}>
                <TwitterIcon>Twitter</TwitterIcon>
            </TwitterShareButton>

            <WhatsappShareButton 
            className={styles.ShareButton}
            url={Pathname}>
                <WhatsappIcon>Whatsapp</WhatsappIcon>
            </WhatsappShareButton>
            </div>
            </div>
        
    )
}

export default ShareButtonModal;