
import './App.css';
import React, { Component} from 'react';
import { connect } from 'react-redux';
import { Route , Switch , Redirect} from 'react-router';
import * as actions from './store/actions/action';
import Layout from './Containers/Layout/Layout';
import Home from './Containers/Home/Home';
import Login from './Containers/Login/Login';
import Notfound from './Containers/Notfound/Notfound';
import Topic from './Containers/Topic/Topic';
import CoursePage from './Containers/CoursePage/CoursePage';
import ContentPage from './Containers/ContentPage/ContentPage';
import SubTopic from './Containers/SubTopic/SubTopic';
// import ComfirmYourAcount from './Containers/ComfirmYourAcount/ComfirmYourAcount';
// import Dashboard from './Containers/Dashboard/Dashboard';
import LazyLoadingFallback from './Components/LazyLoadingFallback/LazyLoadingFallback';
import SearchPage from './Containers/SearchPage/SearchPage.js';
import RedirectPage from './Containers/RedirectPage/RedirectPage';
import ResetPassword from './Containers/ResetPassword/ResetPassword';
import HTMLParser from './Components/HTMLParser/HTMLParser';
const About = React.lazy(()=> import('./Containers/About/About'));
const Dashboard = React.lazy(()=> import('./Containers/Dashboard/Dashboard'));
const ComfirmYourAcount = React.lazy(()=> import('./Containers/ComfirmYourAcount/ComfirmYourAcount'));
const ContactUs = React.lazy(()=> import('./Containers/ContactUs/ContactUs'));
const PrivacyPolicyPage = React.lazy(()=> import('./Containers/PrivacyPolicyPage/PrivacyPolicyPage'));
const TermsOfUse = React.lazy(()=> import('./Containers/TermsOfUse/TermsOfUse'));

class App extends Component {

  componentDidMount(){
  
    this.props.onGetCategory(); 
    if (this.props.token === null && localStorage.getItem('token')) {
      const token = localStorage.getItem('token');
      this.props.onSignIn(token);
      this.props.onGetUserData(token); 
    }
  }
  render(){
    return (
    <>
      <Layout>      
      <Switch>
      <Route path="/About" >
      <React.Suspense fallback={<LazyLoadingFallback />}>
      <About />
      </React.Suspense>
      </Route>

      <Route path="/ContactUs" >
      <React.Suspense fallback={<LazyLoadingFallback />}>
      <ContactUs />
      </React.Suspense>
      </Route>
      <Route path="/Redirect" component={RedirectPage} />
      <Route path="/Home" component={Home} />
      <Redirect exact from="/" to="/Home" />
      <Redirect exact from="/null" to="/Home" />
      <Redirect exact from="/Dashboard" to="/Dashboard/Activities" />
      <Route path="/Login/:Method" component ={Login} />
      <Route path="/Search" component={SearchPage} />
      <Route path="/Dashboard" >
      <React.Suspense fallback={<LazyLoadingFallback />}>
      <Dashboard />
      </React.Suspense>
      </Route>

      <Route path="/Course/:CourseName" component={CoursePage} />
      <Route path="/Content/:CourseType/:CoursePath/:ModuleOrder/:ContentOrder" component={ContentPage} />
      <Route path="/Topic/:TopicName" exact component={Topic} />
      <Route path="/Topic/:TopicName/:SubTopicName" component={SubTopic} />
      <Route path="/ComfirmYourAcount">
      <React.Suspense fallback={<LazyLoadingFallback />}>
      <ComfirmYourAcount />
      </React.Suspense>
      </Route>

      <Route path="/PrivacyPolicy">
      <React.Suspense fallback={<LazyLoadingFallback />}>
      <PrivacyPolicyPage />
      </React.Suspense>
      </Route>
      
      <Route path="/TermsOfUse">
      <React.Suspense fallback={<LazyLoadingFallback />}>
      <TermsOfUse />
      </React.Suspense>
      </Route>
      
      <Route path="/ResetPassword" component={ResetPassword} />
      <Route path="/HTMLParser" component={HTMLParser} />
      <Route component={Notfound} />
      </Switch>

        
      </Layout>
    </>
  );}
}
const mapStateToProps = state => {
  return {
    token : state.token
  }
}
const mapDispatchToProps = dispatch => {
return {
    onSignIn : (token)=> dispatch(actions.SignInSuccess(token)),
    onGetUserData : (token)=> dispatch(actions.StartGetUserData(token)),
    onGetCategory : ()=> dispatch(actions.GetCategoryStart()),

  }
}
export default connect(mapStateToProps , mapDispatchToProps)(App);
