import React , {Component} from 'react';
import { connect } from 'react-redux';
import* as actions from '../../../store/actions/action';
import Message from '../Message';
let Timeout;

class ErrorMessage extends Component{
    state = {
        ErrorType : null,
    }
   componentDidMount(){
    clearTimeout(Timeout);
    
   }
    render(){
        Timeout =  setTimeout(()=>{this.props.onClearError()}, 5000)
        let ErrorMessage = '';
        switch (this.props.ErrorType) {
            case 'ChangeAvatar' : 
                ErrorMessage = 'تغییر عکس پروفایل با مشکل مواجه شد.';
                break;
            case 'ChangeUserData':
                ErrorMessage = 'تغییر اطلاعات کاربری با مشکل مواجه شد.';
                break;
            case 'GetBasicData':
                ErrorMessage = 'دریافت اطلاعات اولیه با مشکل مواجه شد.';
                break;
            case 'SignIn' :
                ErrorMessage = 'رمز عبور یا ایمیل اشتباه است. لطفا دوباره تلاش کنید.';
                break;
            case 'ChangePassword' : 
                ErrorMessage = 'رمز عبور قبلی اشتباه است.';
                break;
            case 'SignUp':
                ErrorMessage = 'ایمیل قبلا ثبت نام شده است.';
                break;
            case 'ResetPassword':
                ErrorMessage = 'ایمیل نامعتبر است.'
                break;
            default :
                ErrorMessage = 'خطا رخ داده است.'

        }
        
        return (
        <>
        {this.props.ErrorType  ?
        <Message Message={ErrorMessage} 
        OnClear={()=>{
            this.props.onClearError();
        if (this.props.ErrorType === 'GetBasicData') {
            document.location.reload();
        }}} 
        Type='Error'  /> : null}
        </>
    )}
}
const mapStateToProps = state => {
    return {
        error : state.error,
        ErrorType : state.ErrorType
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onClearError : ()=> dispatch(actions.ClearError())
    }
}
export default connect(mapStateToProps , mapDispatchToProps )(ErrorMessage)