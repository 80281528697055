import React, { Component } from "react";
import styles from "./Login.module.css";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import * as actions from "../../store/actions/action";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import GoogleLogo from "./img/GoogleLogo.png";
import Paragraph from "../../Components/Paragraph/Paragraph";
import getColor from "../../Components/Utilities/ColorGenerator";
import LampSVG from './img/Lamp.svg';
import Planet from './img/Planet.svg';
import ShatelSVG from './img/Shatel.svg';
import PaperRocket from './img/PaperRocket.svg';
import Rocket1 from './img/Rocket1.svg';
import IdeaLamp from './img/IdeaLamp.svg';
import Ideas from './img/Ideas.svg';
class Login extends Component {
  state = {
    Email: "",
    Password: "",
    Name: "",
    isEmailValid: false,
    isPasswordValid: false,
    isNameValid: false,
    IsTermsValid: false,
  };
  emailValidation = (event) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.setState({ Email: event.target.value });
    if (re.test(event.target.value)) {
      this.setState({ isEmailValid: true });
    } else {
      this.setState({ isEmailValid: false });
    }
  };
  PasswordValidation = (event) => {
    this.setState({ Password: event.target.value });
    if (event.target.value.length >= 8) {
      this.setState({ isPasswordValid: true });
    } else {
      this.setState({ isPasswordValid: false });
    }
  };
  NameValidation = (event) => {
    if (event.target.value.length <= 15) {
      this.setState({ Name: event.target.value });
    }
    if (event.target.value.length > 3) {
      this.setState({ isNameValid: true });
    } else {
      this.setState({ isNameValid: false });
    }
  };
  TermsValid = (event) => {
    this.setState({ IsTermsValid: event.target.checked });
  };
  SignUpHandler = () => {
    if (
      this.state.isEmailValid &&
      this.state.isNameValid &&
      this.state.isPasswordValid &&
      this.state.IsTermsValid
    ) {
      this.props.onSignUp(
        this.state.Name,
        this.state.Email,
        this.state.Password
      );
    }
  };
  SignInHandler = () => {
    if (this.state.isEmailValid && this.state.isPasswordValid) {
      this.props.onSignInInit();
      this.props.onSignIn(this.state.Email, this.state.Password);
    }
  };
  SignInWithGoogleHandler = () => {
    localStorage.setItem("RedirectPath", this.props.redirectPath);
  };
  componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }
  render() {
    let Title = null;
    let CallToAction = null;
    let EmailErrorMessage = <span></span>;
    let PasswordErrorMessage = <span></span>;
    let NameInput = null;
    let Agreements = null;
    let HeadSection = null;
    let ChangeMethod = null;
    let ResetPass = null;
    // let RepeatPasswordErrorMessage = null;
    if (this.props.match.params.Method.toString() === "SignUp") {
      HeadSection = (
        <Helmet>
          <title>ثبت نام | براکت آکادمی</title>
          <meta name="description" content="ثبت نام در براکت آکادمی" />
          <meta name="title" content="ثبت نام | براکت آکادمی" />
          <meta property="og:type" content="login" />
          <meta property="og:title" content="ثبت نام | براکت آکادمی" />
          <meta property="og:description" content="ثبت نام | براکت آکادمی" />
        </Helmet>
      );
      Title = <h1 className={styles.Title}> ثبت نام در براکت آکادمی</h1>;
      CallToAction = (
        <button
          disabled={
            this.state.IsTermsValid &&
            this.state.isEmailValid &&
            this.state.isNameValid &&
            this.state.isPasswordValid
              ? false
              : true
          }
          onClick={this.SignUpHandler}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              this.SignUpHandler();
            }
          }}
          className={styles.CallToAction}
        >
          ثبت نام
        </button>
      );
      Agreements = (
        <div className={styles.Agreements}>
          <Paragraph> با قوانین براکت آکادمی موافقم</Paragraph>
          <input
            type="Checkbox"
            checked={this.state.IsTermsValid}
            onChange={this.TermsValid}
          />
        </div>
      );
      NameInput = (
        <>
          <div className={styles.ErrorAndLabelContainer}>
            {this.state.isNameValid || this.state.Name.trim() === "" ? null : (
              <span className={styles.ErrorMessage}>
                نام باید بیشتر از 3 حرف باشد*
              </span>
            )}
          </div>
          <input
            placeholder="نام"
            className={styles.Input}
            value={this.state.Name}
            onChange={this.NameValidation}
          />
        </>
      );
      ChangeMethod = (
        <div className={styles.ChangeMethod}>
          <span> حساب کاربری دارید؟ </span>
          <Link to="/Login/SignIn"> ورود </Link>
        </div>
      );
      ResetPass = null;
    } else if (this.props.match.params.Method.toString() === "SignIn") {
      HeadSection = (
        <Helmet>
          <title>ورود | براکت آکادمی</title>
          <meta name="description" content="ورود براکت آکادمی" />
          <meta name="title" content="ورود | براکت آکادمی" />
          <meta property="og:type" content="login" />
          <meta property="og:title" content="ورود | براکت آکادمی" />
          <meta property="og:description" content="ورود | براکت آکادمی" />
        </Helmet>
      );
      // if (this.state.isNameValid === false) {
      //     this.setState({isNameValid : true})
      // }
      Title = <h1 className={styles.Title}>ورود به براکت آکادمی</h1>;
      ResetPass = (
        <Link className={styles.ResetPass} to="/ResetPassword">
          رمزعبور خود را فراموش کرده اید؟
        </Link>
      );
      CallToAction = (
        <button
          onClick={this.SignInHandler}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              this.SignInHandler();
            }
          }}
          disabled={
            this.state.isEmailValid && this.state.isPasswordValid ? false : true
          }
          className={styles.CallToAction}
        >
          ورود
        </button>
      );

      ChangeMethod = (
        <div className={styles.ChangeMethod}>
          <span> حساب کاربری ندارید؟ </span>
          <Link to="/Login/SignUp"> ثبت نام </Link>
        </div>
      );
    }

    if (this.state.isEmailValid || this.state.Email.trim() === "") {
      EmailErrorMessage = null;
    } else {
      EmailErrorMessage = (
        <span className={styles.ErrorMessage}>ایمیل معتبر نیست*</span>
      );
    }
    if (this.state.isPasswordValid || this.state.Password.trim() === "") {
      PasswordErrorMessage = null;
    } else {
      PasswordErrorMessage = (
        <span className={styles.ErrorMessage}>
          رمز عبور باید بیش از 8 کارکتر باشد*
        </span>
      );
    }
    let Redirection = null;
    if (this.props.token && this.props.redirectPath) {
      if (this.props.redirectPath.includes("Login")) {
        Redirection = <Redirect to="/" />;
      } else {
        Redirection = <Redirect to={this.props.redirectPath} />;
      }
    }
    if (this.props.token && !this.props.redirectPath) {
      Redirection = <Redirect to="/" />;
    }
    let BackgroundColor;

    BackgroundColor = BackgroundColor ? BackgroundColor : getColor();
    return (
      <div
        className={styles.Login}
        style={{ backgroundColor: BackgroundColor }}
      >
        {Redirection}
        {HeadSection}
        <div className={styles.InputsContainer}>
          {Title}
          <div className={styles.Inputs}>
            <a
              onClick={this.SignInWithGoogleHandler}
              href="https://bracketapi.ir/user/google-auth"
              className={styles.SignInWithGoogle}
            >
              <img src={GoogleLogo} alt="لوگو گوگل" />
              <span>ورود با گوگل</span>
              <span></span>
            </a>
            <div className={styles.Or}>
              <div></div>
              <span>یا</span>
              <div></div>
            </div>
            {NameInput}
            <div className={styles.ErrorAndLabelContainer}>
              {EmailErrorMessage}
            </div>

            <input
              className={styles.Input}
              value={this.state.Email}
              type="Email"
              placeholder="ایمیل"
              onChange={(event) => {
                this.emailValidation(event);
              }}
            />

            <div className={styles.ErrorAndLabelContainer}>
              {PasswordErrorMessage}
            </div>
            <input
              className={styles.Input}
              value={this.state.Password}
              onChange={this.PasswordValidation}
              type="Password"
              placeholder="رمز عبور"
            />
            {Agreements}
          </div>

          {CallToAction}
          {ResetPass}
          {ChangeMethod}
        </div>
        <div className={styles.Description}>
            <img className={styles.SVGImage} 
            src={PaperRocket} alt=""
            style={{left : '2rem' , transform : 'scaleX(-1)'}} />
            <img className={styles.SVGImage} 
            src={IdeaLamp} alt=""
            style={{top : '2rem'}} />
            <img className={styles.SVGImage} 
            src={PaperRocket} alt=""
            style={{right : '2rem'}} />
            <img className={styles.SVGImage} 
            src={LampSVG} alt=""
            style={{bottom : '2rem'}} />
            <img className={styles.SVGImage} 
            src={Ideas} alt=""
            style={{bottom : '4rem' , right : '4rem'}} />
            <img className={styles.SVGImage} 
            src={Planet} alt=""
            style={{bottom : '4rem' , left : '4rem'}} />
            <img className={styles.SVGImage} 
            src={ShatelSVG} alt=""
            style={{top : '4rem' , left : '4rem'}} />
            <img className={styles.SVGImage} 
            src={Rocket1} alt=""
            style={{top : '4rem' , right : '4rem'}} />
            <h2>خلاقیت‌هایت را کشف کن!</h2>
            <p> با استفاده از آموزش‌های براکت‌آکادمی،می‌تونی مهارت‌های جدید کسب کنی،
                 استعدادهات رشد بدی و از آموزش دیدن لذت ببری!</p>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onSignUp: (name, email, password) =>
      dispatch(actions.SignUpStart(name, email, password)),
    onSignInInit: () => dispatch(actions.SignInInit()),
    onSignIn: (email, password) =>
      dispatch(actions.SignInStart(email, password)),
  };
};
const mapStateToProps = (state) => {
  return {
    token: state.token,
    redirectPath: state.redirectPath,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
