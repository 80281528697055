export const TOGGLE_SIDE_DRAWER = 'TOGGLE_SIDE_DRAWER';
export const CLOSE_SIDE_DRAWER = 'CLOSE_SIDE_DRAWER';
export const SIGNUP_START = 'SIGNUP_START';
export const SIGNIN_START = 'SIGNIN_START';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_INIT = 'SIGNIN_INIT';
export const SIGNIN_FAIL = 'SIGNIN_FAIL';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const LOGOUT = 'LOGOUT';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const ERROR_SETTER = 'ERROR_SETTER';
export const LOADING_SETTER = 'LOADING_SETTER';
export const CLEAR_LOADING = 'CLEAR_LOADING';
export const SET_REDIRECTPATH = 'SET_REDIRECTPATH';
export const REMOVE_REDIRECTPATH = 'REMOVE_REDIRECTPATH';

