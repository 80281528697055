import React from 'react';
import LoginButton from '../Buttons/LoginButton/LoginButton';
import './SideDrawer.css';
import styles from './SideDrawer.module.css';
import Courses from '../Courses/Courses';
// import HamMenu from '../HamMenu/HamMenu';
import { connect } from 'react-redux';
import AccountButton from '../AccountButton/AccountButton';
import * as actions from '../../store/actions/action';
import Logo from '../Logo/Logo';
const sideDrawer = (props)=>{
    
    return (
        <>
       {props.show && window.innerWidth <= 900 ? <div className={[styles.SideDrawer , 'SideDrawer'].join(' ')}>
           <div className={styles.LogoContainer}>
            {/* <HamMenu /> */}
            <Logo />
           </div>
            <LoginButton />
            <AccountButton />
            <Courses />
        </div> : null}
        </>
    )
}
const mapStateToProps = state => {
    return {
        show : state.sideDrawerOpen
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onToggleSideDrawer : ()=> dispatch(actions.ToggleSideDrawer()),
        
    }
}
export default connect(mapStateToProps , mapDispatchToProps)(sideDrawer);