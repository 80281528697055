import React from "react";
import { withRouter, useLocation } from "react-router-dom";
import styles from "./LoginButton.module.css";
import * as actions from "../../../store/actions/action";
import { connect } from "react-redux";

const LoginButton = (props) => {
  const location = useLocation();
  let loginButton = (
    <div
      onClick={props.onCloseSideDrawer}
      className={[styles.LoginButton, props.white ? styles.White : null].join(
        " "
      )}
    >
      <span
        className={styles.Link}
        onClick={() => {
          props.SetRedirectPath(location.pathname);
          props.history.push("/Login/SignIn");
        }}
      >
        ورود
      </span>
      <div onClick={props.onCloseSideDrawer} className={styles.Line}></div>
      <span
        className={[styles.Link, styles.SignUp].join(" ")}
        onClick={() => {
          props.SetRedirectPath(location.pathname);
          props.history.push("/Login/SignUp");
        }}
      >
        ثبت نام
      </span>
    </div>
  );
  if (props.token) {
    loginButton = null;
  }
  return loginButton;
};
const mapDispatchToProps = (dispatch) => {
  return {
    onCloseSideDrawer: () => dispatch(actions.CloseSideDrawer()),
    SetRedirectPath: (path) => dispatch(actions.SetRedirectPath(path)),
  };
};
const mapStateToProps = (state) => {
  return {
    token: state.token,
    loading: state.loading,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginButton));
