import React from 'react';
import styles from './Slide.module.css';
import {Link} from 'react-router-dom';
import TimeSeter from '../../../Utilities/TimeSeter';
const Slide = (props)=>{
    return (
        <Link to={props.Path} className={styles.Slide} >
            <img className={styles.Image} src={props.Image} alt={props.Name} />
            <span className={styles.Name}>{props.Name} </span>
            <div>
            <span className={styles.Parts}>{props.Parts.toString().toPersinaDigit()} قسمت</span>
            <span className={styles.Time}>{TimeSeter(props.Time)} </span>
            </div>
        </Link>
    )
}

export default Slide