import React, { Component } from 'react';
import styles from './VideoContent.module.css';
import VideoPlayer from '../../../Components/VideoPlayer/VideoPlayer';
import LikeButton from '../../../Components/Buttons/LikeButton/LikeButton';
import SaveButton from '../../../Components/Buttons/SaveButton/SaveButton';
import ShareButton from '../../../Components/Buttons/ShareButton/ShareButton';
import Paragraph from '../../../Components/Paragraph/Paragraph';

class VideoContent extends Component {

    render(){
        const videoJsOptions = {
            autoplay: false,
            controls: true,
            playbackRates: [0.5, 1, 1.25, 1.5, 2],
            poster : this.props.cover,
            sources: [{
              src: this.props.video,
              type: 'application/x-mpegURL',
              
              
            }],
            seekbar : true,
            plugins : {
                seekButtons : {
                    forward : 15,
                    back : 15
                }
            }
          }
        return(
            <>
            
           
            <div className={[styles.VideoContainer ,this.props.video 
            ? null : styles.Loading].join(' ')}>
           {this.props.video ? <VideoPlayer {...videoJsOptions} 
           AddView = {this.props.AddViewHandler}/> : null}
           </div>
           

            {/* <div className={styles.Spiliter}></div> */}

           <div className={styles.ViewsAndTitleContainer}>
           <span className={styles.Views}>
               
               <strong> {this.props.views ?
               this.props.views.toString().toPersinaDigit() 
               : '0'.toPersinaDigit() } </strong>
               {/* <span>  بازدید  </span>  */}
               
               <svg xmlns="http://www.w3.org/2000/svg" 
               xmlnsXlink="http://www.w3.org/1999/xlink" 
               version="1.1" id="Capa_1" x="0px" y="0px" 
               viewBox="0 0 488.85 488.85"  
               xmlSpace="preserve">
               <g>
                   <path d="M244.425,98.725c-93.4,0-178.1,51.1-240.6,134.1c-5.1,6.8-5.1,16.3,0,23.1c62.5,83.1,147.2,134.2,240.6,134.2   s178.1-51.1,240.6-134.1c5.1-6.8,5.1-16.3,0-23.1C422.525,149.825,337.825,98.725,244.425,98.725z M251.125,347.025   c-62,3.9-113.2-47.2-109.3-109.3c3.2-51.2,44.7-92.7,95.9-95.9c62-3.9,113.2,47.2,109.3,109.3   C343.725,302.225,302.225,343.725,251.125,347.025z M248.025,299.625c-33.4,2.1-61-25.4-58.8-58.8c1.7-27.6,24.1-49.9,51.7-51.7   c33.4-2.1,61,25.4,58.8,58.8C297.925,275.625,275.525,297.925,248.025,299.625z"/>
               </g>
               <g>
               </g>
               <g>
               </g>
               <g>
               </g>
               <g>
               </g>
               <g>
               </g>
               <g>
               </g>
               <g>
               </g>
               <g>
               </g>
               <g>
               </g>
               <g>
               </g>
               <g>
               </g>
               <g>
               </g>
               <g>
               </g>
               <g>
               </g>
               <g>
               </g>
               </svg>
               
               </span>
           <h1 className={[styles.ContentTitle , this.props.title ? null : styles.Loading].join(' ')}>{this.props.title}</h1>
            
           <div className={styles.ButtonsContainer}>
                    <LikeButton 
                    IsLiked = {this.props.isLiked}
                    Count={this.props.likes}
                    LikeHandler = {this.props.LikeHandler} />
                    <SaveButton 
                    SaveHandler = {this.props.SaveHandler} 
                    isSaved={this.props.isSaved} />
                    <ShareButton /> 
            </div>
              
               
            </div>
            {this.props.CourseDetailAndDownLoadFiles}
            <div className={styles.Spiliter}></div>
         
       {/* <div className={styles.Spiliter}></div> */}

       <div>
       <h2 className={styles.DescriptionTitle}>توضیحات</h2>
       
       <Paragraph hasHTMLElement>{this.props.description}</Paragraph>
       </div>
            </>
        )
    }
}

export default VideoContent;