import React from 'react';
import { connect } from 'react-redux';
import styles from './UserType.module.css';
const userType = (props)=>{
    let text = null;
    let style = null;
    switch (props.userData.groups[0]) {
        case "Super Staff" :
            text = 'اعضای ویژه';
            style = styles.SuperStaff;
            break;
        case "Premium" :
            text = 'پریمیوم';
            style = styles.Premium;
            break;
        case "Free" :
            text = 'رایگان';
            style = styles.Free;
            break;
        default : 
            text = 'نامشخص';
            style = styles.UnCategorized ;
        
    }
    return (
        <div className={[styles.UserType , style].join(' ')}>
            {text}
        </div>
    )
}
const mapStateToProps = state => {
    return {
        userData : state.userData
    }
}
export default connect(mapStateToProps)(userType);