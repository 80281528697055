import React from 'react';
import { connect } from 'react-redux';
import styles from './Loading.module.css';
import Spinner from '../Spinner/Spinner';
import Paragraph from '../Paragraph/Paragraph';
const loading = (props)=> {

    return (
        <>
        {props.isLoading ? <div className={styles.LoadingPage}>
        
        <h1>لطفا منتظر بمانید ...</h1>
        <Paragraph>این فرایند ممکن است چند ثانیه‌ای طول بکشد.</Paragraph>
        <div className={styles.Loading}>
        <Spinner />
        </div>
        
        
        
        </div> : null}
        </>
    )
}
const mapStateToProps = state => {
    return {
        isLoading : state.loading,
    }
}
export default connect(mapStateToProps)(loading);