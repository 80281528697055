import React , {Component} from 'react';
import Message from '../Message';
let Timeout;

class SuccessMessage extends Component{

   componentDidMount(){
    clearTimeout(Timeout);
    
   }
    render(){
        Timeout =  setTimeout(()=>{this.props.OnClear()}, 5000)
 
        return (
        <Message Message={this.props.children} OnClear={this.props.OnClear} Type='Success'  /> 
        
    )}
}
export default SuccessMessage;