import React from 'react';
import Tooltip from '../../Tooltip/Tooltip';
import styles from './FileDownloadButton.module.css';
const FileDownloadButton = (props)=>{
    
    return (
        <a
        className={[styles.FileDownloadButton , props.Loading ? styles.Loading : null].join(' ')} 
        href={props.FileLink}
        download>
            <button disabled={props.FileLink ? false : true}>
            <span>{props.children}</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><g id="Solid"><path d="m239.029 384.97a24 24 0 0 0 33.942 0l90.509-90.509a24 24 0 0 0 0-33.941 24 24 0 0 0 -33.941 0l-49.539 49.539v-262.059a24 24 0 0 0 -48 0v262.059l-49.539-49.539a24 24 0 0 0 -33.941 0 24 24 0 0 0 0 33.941z"/><path d="m464 232a24 24 0 0 0 -24 24v184h-368v-184a24 24 0 0 0 -48 0v192a40 40 0 0 0 40 40h384a40 40 0 0 0 40-40v-192a24 24 0 0 0 -24-24z"/></g></svg>
            </button>
        {!props.FileLink ?<div className={styles.TooltipContainer}> <Tooltip>فایلی موجود نیست</Tooltip></div> : null}
        </a>
    )
}
export default FileDownloadButton;