import api from '../../api/api';
import * as actionTypes from './actionTypes';
export const ToggleSideDrawer = ()=> {
    return {
        type : actionTypes.TOGGLE_SIDE_DRAWER,
    }
}
export const CloseSideDrawer = ()=> {
    return {
        type : actionTypes.CLOSE_SIDE_DRAWER,
    }
}

export const SignUpStart = (name ,email , password )=> {
    
    return dispatch => {
        dispatch(LoadingSetter())
        api.post('/user/create' , {"name":name ,"email":email , "password":password } )
        .then(
            
            response =>{
               document.location.replace('/ComfirmYourAcount')
            }
        )
        .catch (
            err => {
                dispatch(ErrorSetter(err , 'SignUp'))
                dispatch(ClearLoading());
            }
        )
    }
}
export const SignInInit = () => {
    return {
        type : actionTypes.SIGNIN_INIT
    }
}
export const SignInStart = (email , password) =>{
    return dispatch => {
        api.post('/user/me/token' , {"email":email , "password":password } )
        .then(
            response => {
            dispatch(SignInSuccess(response.data.token));
            dispatch(StartGetUserData(response.data.token));
            dispatch(ClearError());
            localStorage.setItem('token',response.data.token);
    }
        )
        .catch (
            error =>{
                dispatch(ErrorSetter(error , 'SignIn'));
                
            }
        )
    }
}

export const SignInSuccess = (token)=>{
    return {
        type : actionTypes.SIGNIN_SUCCESS,
        token : token
    }
}

export const StartGetUserData = (token)=>{
    return dispatch => {
        api.get('/user/me' , {headers: {
            Authorization : 'Token ' + token
        }})
        .then(res => {
            dispatch(GetUserDataSuccess(res.data))

        }
        )
        .catch (error=>{
            if (error.response) {
                if (error.response.data) {
                    if(error.response.data.detail === 'Token is expired!' 
                || error.response.data.detail === 'Invalid token.'){
                    dispatch(Logout());
                }
                }
                
            }
           
        })
    }
}

const GetUserDataSuccess = (userData) => {
    return {
        type : actionTypes.GET_USER_DATA_SUCCESS,
        userData : userData,
    }
}

export const GetCategoryStart = ()=>{
    return dispatch => {
        api.get('/category/?is_tree=1&exclude=uncategorized')
        .then(
            res => {
                dispatch(GetCategorySuccess(res.data));
                
            }
        )
        .catch(
            err => {
                dispatch(ErrorSetter(err , 'GetBasicData'))
            }
        )
        
    }
}
const GetCategorySuccess = (category) => {
    return {
        type : actionTypes.GET_CATEGORY_SUCCESS,
        category : category
    }
}
export const StartChangeUserAvatar = (avatar , token) => {
    return dispatch => {
        dispatch(LoadingSetter());
        const fd = new FormData();
        fd.append('avatar' , avatar)
        api.patch('/user/me' , fd ,{
            headers: {
            Authorization : 'Token ' + token,
            'content-type' : 'multipart/form-data'
            },
            onUploadProgress : ProgressEvent => {
                
                // console.log(Math.round(ProgressEvent.loaded / ProgressEvent.total * 100));
            }
    
    })
    .then(
        res => {
            dispatch(GetUserDataSuccess(res.data));
            document.location.reload();
        })
    .catch(
        err => {
        dispatch(ErrorSetter(err , 'ChangeAvatar'))
    })
    
    }
}
export const StartChangeUserData = (name ,gender, birthDate , token )=>{
    return dispatch => {
        dispatch(LoadingSetter());
        api.patch('/user/me' , {
            'name' : name,
            'gender' : gender,
            'birth_date' : birthDate
        },
        {
            headers: {
            Authorization : 'Token ' + token}})
        .then(
            res => {
                dispatch(GetUserDataSuccess(res.data))
            }
        )
        .catch(
            err => {
                dispatch(ErrorSetter(err , 'ChangeUserData'))
            }
        )
    }
}
export const StartChangeUserPassword = (LastPassword ,NewPassword , token )=>{
    return dispatch => {
        dispatch(LoadingSetter());
        api.post('/user/me/changepass' , {
            'old_pass' : LastPassword,
            'new_pass' : NewPassword,
            
        },
        {
            headers: {
            Authorization : 'Token ' + token}})
        .then(
            res => {
                dispatch(ClearLoading())
            }
        )
        .catch(
            err => {
                dispatch(ErrorSetter(err , 'ChangePassword'))
            }
        )
    }
}
export const LoadingSetter = ()=> {
    return {
        type : actionTypes.LOADING_SETTER
    }
}
export const ClearLoading = ()=> {
    return {
        type : actionTypes.CLEAR_LOADING,
    }
}
export const ErrorSetter = (error , ErrorType)=>{
    return {
        type : actionTypes.ERROR_SETTER,
        error : error,
        ErrorType : ErrorType
    }
}
export const ClearError = ()=>{
    return{
        type : actionTypes.CLEAR_ERROR
    }
}
export const Logout = ()=>{
    return {
        type : actionTypes.LOGOUT,
    }
}

export const SetRedirectPath = (path)=>{
    return {
        type : actionTypes.SET_REDIRECTPATH,
        path : path
    }
}
export const RemoveRedirectPath = ()=>{
    return {
        type : actionTypes.REMOVE_REDIRECTPATH
    }
}