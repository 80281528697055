import React from 'react';
import styles from './Backdrop.module.css';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/action';

const backdrop = (props)=> {
    return (
        <>
        {props.show ? <div className={styles.Backdrop} onClick={props.onCloseSideDrawer}></div> : null}
        </>
    )
}
const mapDispatchToProps = dispatch => {
    return {
        onCloseSideDrawer : ()=> dispatch(actions.CloseSideDrawer()),
    }
}
const mapStateToProps = state => {
    return {
        show : state.sideDrawerOpen
    }
}
export default connect(mapStateToProps , mapDispatchToProps)(backdrop);