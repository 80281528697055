import React, { Component } from 'react';
import styles from './ResetPassword.module.css';
import {Helmet} from 'react-helmet';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/action';
import { Redirect } from 'react-router';
import api from '../../api/api';
class ResetPassword extends Component {
    state = {
        Email : '',
        isEmailValid : false,
        isEmailSend : false
    }
    ResetPasswordHandler = ()=>{
        if (this.state.isEmailValid  && this.state.Email.length > 0) {
            this.props.LoadingSetter();
            api.post('/user/changepass' , {
                'email' : this.state.Email
            })
            .then ((res)=>{
                this.setState({isEmailSend : true});
                this.props.ClearLoading();

            })
            .catch((err)=>{
                this.props.ClearLoading();
                this.props.ErrorSetter(err , 'ResetPassword')
            })
        }
    }
    emailValidation = (event)=> {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.setState({Email : event.target.value});
        if (re.test(event.target.value)) {
            this.setState({isEmailValid : true})
        }
        else {
            this.setState({isEmailValid:false})
        }
    }
    render () {
        let EmailErrorMessage;
        if (this.state.isEmailValid || this.state.Email.trim()=== '') {
            EmailErrorMessage = null;
        }
        else {
            EmailErrorMessage = <span className={styles.ErrorMessage}>ایمیل معتبر نیست*</span>
        }
        return (
            <>
            {this.props.token ? <Redirect to='/' /> : null}
              <Helmet>
            <title> بازیابی پسورد | براکت آکادمی</title>
            <meta name="description" content="بازیابی پسورد" />
            <meta property="og:type" content="topic"/>
            <meta property="og:title" content=' بازیابی پسورد | براکت آکادمی'/>
            <meta property="og:description" content=' بازیابی پسورد | براکت آکادمی'/>
            </Helmet>
            <div className={styles.ResetPassword}>
                <div className={styles.InputsContainer}>
                <h1 className={styles.Title}>بازیابی رمز عبور</h1>
                
                {!this.state.isEmailSend ? <div className={styles.Inputs}>
                <div className={styles.ErrorAndLabelContainer}>
                {EmailErrorMessage}
                <input className={styles.Input}
                value={this.state.Email}
                type="Email"
                placeholder="ایمیل"
                onChange={(event)=>{
                    this.emailValidation(event)
                }}/>
                </div>
                </div> :
                <h1 className={styles.SuccessMessage}>لینک بازیابی رمز عبور به ایمیل شما ارسال شد.</h1>}

             { !this.state.isEmailSend ?  <button
            disabled={ this.state.isEmailValid 
             ? false : true}
            onClick={this.ResetPasswordHandler} 
            onKeyDown={(event)=>{
                if (event.key === 'Enter') {
                
                }
            }} 
            className={styles.CallToAction}>بازیابی رمز عبور</button>
        :null}
                </div>
            </div>
          

            </>
        )
    }
}
const mapStateToProps = state =>{
    return {
        token : state.token
    }
}
const mapDispatchToProps = dispatch => {
    return {
        ErrorSetter : (error , errorType)=> {dispatch(actions.ErrorSetter(error , errorType))},
        LoadingSetter : ()=>{dispatch(actions.LoadingSetter())},
        ClearLoading : ()=>{dispatch(actions.ClearLoading())}
    }
}
export default connect(mapStateToProps , mapDispatchToProps )(ResetPassword);