import * as actionTypes from "../actions/actionTypes";
const initialState = {
  sideDrawerOpen: false,
  token: null,
  loading: false,
  userData: null,
  error: null,
  ErrorType: null,
  category: null,
  redirectPath: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_SIDE_DRAWER:
      const lastSideDrawer = state.sideDrawerOpen;
      return { ...state, sideDrawerOpen: !lastSideDrawer };
    case actionTypes.CLOSE_SIDE_DRAWER:
      return {
        ...state,
        sideDrawerOpen: false,
      };
    case actionTypes.SIGNUP_START:
      return {
        ...state,
        name: action.name,
        email: action.email,
        password: action.password,
      };
    case actionTypes.SIGNIN_INIT:
      return {
        ...state,
        loading: true,
        error: null,
        ErrorType: null,
      };
    case actionTypes.SIGNIN_SUCCESS:
      return {
        ...state,
        token: action.token,
        loading: false,
      };
    case actionTypes.SIGNIN_FAIL:
      return {
        ...state,
        error: action.error,
        ErrorType: action.ErrorType,
        loading: false,
      };
    case actionTypes.GET_USER_DATA_SUCCESS:
      return {
        ...state,
        userData: action.userData,
        loading: false,
      };
    case actionTypes.CLEAR_ERROR:
      return {
        ...state,
        error: null,
        ErrorType: null,
      };
    case actionTypes.LOGOUT:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        redirectPath: null,
        userData : null,
      };
    case actionTypes.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        category: action.category,
      };
    case actionTypes.LOADING_SETTER:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ERROR_SETTER:
      return {
        ...state,
        error: action.error,
        ErrorType: action.ErrorType,
        loading: false,
      };
    case actionTypes.CLEAR_LOADING:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.SET_REDIRECTPATH:
      return {
        ...state,
        redirectPath: action.path,
      };
    case actionTypes.REMOVE_REDIRECTPATH:
      return {
        ...state,
        redirectPath: null,
      };
    default:
      return state;
  }
};

export default reducer;
