import React, { Component } from 'react';
import styles from './SearchPage.module.css';
import CourseTopic from '../../Components/CourseTopic/CourseTopic';
import { connect } from 'react-redux';
import EmptyBox from '../../Components/EmptyBox/EmptyBox';
import CourseTopicSkeleton from '../../Components/CourseTopic/CourseTopicSkeleton/CourseTopicSkeleton';
import VideoItem from '../../Components/VideoItem/VideoItem';
import VideoItemSkeleton from '../../Components/VideoItem/VideoItemSkeleton/VideoItemSkeleton';
import ArticleItem from '../../Components/ArticleItem/ArticleItem';
import { Helmet } from 'react-helmet';
import ArticleItemSkeleton from '../../Components/ArticleItem/ArticleItemSkeleton/ArticleItemSkeleton';
import CourseGridContainer from '../../Components/GridContainers/CourseGridContainer/CourseGridContainer';
import ContentGridContainer from '../../Components/GridContainers/ContentGridContainer/ContentGridContainer';
import api from '../../api/api';
class SearchPage extends Component {
    state= {
        SearchValue : '',
        courses : null,
        videos : null,
        articles : null,
        serachResultCount : 0,
        serachResultCountCourse : 0,
    }
    start() {
        this.setState({
            SearchValue : '',
            courses : null,
            videos : null,
            serachResultCount : 0,
        })
        let Headers = {};
        if (this.props.token) {
            Headers = {
                Authorization : 'Token ' + this.props.token
            };
        }
        else {
            Headers = {};
        }
        const query = new URLSearchParams(this.props.location.search);
        if (query.get('SearchValue')) {
            if (query.get('SearchValue').trim() === '') {
                this.props.history.push('/');
            }
        }
        else {
            this.props.history.push('/');

        }
       
        api.get('/algorithm/search?s=' + query.get('SearchValue') , {
            headers : Headers
        })
        .then((res)=>{
            console.log(res.data)
            this.setState({
                courses : res.data.courses, 
                videos : res.data.contents.filter(content=>{
                    if (content.content.type === 'video') {
                        return true;
                    }
                    return null;
                }),
                articles : res.data.contents.filter(content=>{
                    if (content.content.type === 'text') {
                        return true;
                    }
                    return null;

                }),
                serachResultCount : res.data.courses.length + res.data.contents.length,
                serachResultCountCourse : res.data.courses.length
            })
        })
    }
    componentDidMount(){
        this.start();
       
    }
    componentDidUpdate (preProps , preState) {
        const NewQuery = new URLSearchParams(this.props.location.search);
        const OldQuery = new URLSearchParams(preProps.location.search);

        if (NewQuery.get('SearchValue') !== OldQuery.get('SearchValue')) {
            this.start();
        }

    }
    render(){
        const query = new URLSearchParams(this.props.location.search);
        let CoursesClassName = styles.Courses;
        let Courses = (
            <CourseTopicSkeleton />
        ) ;
        if (this.state.courses && this.state.courses.length !==0) {
            Courses = this.state.courses.map((course , index)=>{
                return <CourseTopic
                Path = {'/Course/' + course.path}
                Progress = {course.progress}
                CourseTime = {course.time}
                CourseCount = {course.parts.toString()}
                Title={course.name}
                Src={course.image}
                
                key={course.id}/>
            })
        }
        if (this.state.courses && this.state.courses.length ===0) {
            Courses = <EmptyBox />;
            CoursesClassName = styles.EmptyContainer;
        }
        let Videos = <VideoItemSkeleton />;
        let VideosClassName = styles.Videos;
        if (this.state.videos && this.state.videos.length !==0) {
            Videos = this.state.videos.map((video)=>{
                return <VideoItem
                
                title={video.content.title}
                cover = {video.content.cover}
                courseImage = {video.course.image}
                topic = {video.course.name}
                duration={video.content.duration.split('.')[0]}
                topicLink={video.course.path}
                videoLink={'Content/Video/' 
                + video.course.path + '/'
                + video.module.order + '/'
                + video.content.order}
                key={video.content.id}
               />
            })
        }
        if (this.state.videos && this.state.videos.length ===0) {
            Videos = <EmptyBox />;
            VideosClassName = styles.EmptyContainer;
        }

        let Articles = <ArticleItemSkeleton />;
        let ArticlesClassName = styles.Articles;
        if (this.state.articles && this.state.articles.length !==0) {
            Articles = this.state.articles.map((article)=>{
                return <ArticleItem
                
                title={article.content.title}
                cover = {article.content.cover}
                courseImage = {article.course.image}
                topic = {article.course.name}
                duration={article.content.duration.split('.')[0]}
                topicLink={article.course.path}
                description = {article.content.description}
                articleLink={'Content/Article/' 
                + article.course.path + '/'
                + article.module.order + '/'
                + article.content.order}
                key={article.content.id}
               />
            })
        }
        if (this.state.articles && this.state.articles.length ===0) {
            Articles = <EmptyBox />;
            ArticlesClassName = styles.EmptyContainer;
        }
        return (
            <>
             <Helmet>
            <title>
                براکت آکادمی - جست و جو
            </title>
            <meta 
            name="description" 
            content={"براکت آکادمی - جست و جو" } />
            <meta name="title" content='جست و جو | براکت آکادمی' />
            <meta property="og:type" content="topic"/>
            <meta property="og:title" content='جست و جو | براکت آکادمی'/>
            <meta property="og:description" content='جست و جو | براکت آکادمی'/>
           
            </Helmet>
            <div className={styles.SearchPage}>
                <div className={styles.Header}>
                <span className={styles.Title}><span>عبارت جست و جو شده: </span> <span className={styles.SearchValue}>{query.get('SearchValue')}</span> </span>
                <span className={styles.Title}> <span>نتایج جستوجو : </span>
                <div className={styles.SearchResultsCountContainer}>
                <a href="#Courses" className={styles.SearchResultCountNum}> 
                <span  className={styles.SearchResultCountTitle}>آموزش</span> 
                <span>
                {this.state.serachResultCountCourse.toString().toPersinaDigit()}
                </span>
                </a>
                 <a href="#Videos" className={styles.SearchResultCountNum}> 
                <span  className={styles.SearchResultCountTitle}>ویدیو </span> 
                <span>
                {this.state.videos ? this.state.videos.length.toString().toPersinaDigit() : '0'.toPersinaDigit()}
                </span>
                 </a>
                 <a href="#Articles" className={styles.SearchResultCountNum}> 
                <span  className={styles.SearchResultCountTitle}>مقاله </span> 
                <span>
                {this.state.articles ? this.state.articles.length.toString().toPersinaDigit() : '0'.toPersinaDigit()}
                </span>
                 </a>
                 <span className={styles.SearchResultCountNum}> 
                 <strong className={styles.SearchResultCountTitle}>مجموع</strong> 
                 <span>
                {this.state.serachResultCount.toString().toPersinaDigit()}
                </span>
                 </span>
                </div>
                
                 </span>
                </div>
               <div className={styles.SearchResult}>
                <h1 className={styles.SearchResultTitle}>آموزش‌ها</h1>
                
                <CourseGridContainer id="Courses" className={CoursesClassName}>
                {Courses}
                </CourseGridContainer >
               </div>
               <div className={styles.Line}></div>
               <div className={styles.SearchResult}>
                <h1 className={styles.SearchResultTitle}>ویدیوها</h1>
                <ContentGridContainer id="Videos" className={VideosClassName}>
                {Videos}
                </ContentGridContainer>
               </div>
               <div className={styles.Line}></div>
               <div className={styles.SearchResult}>
                <h1 className={styles.SearchResultTitle}>مقاله‌ها</h1>
                
                <ContentGridContainer id="Articles" className={ArticlesClassName}>
                {Articles}
                </ContentGridContainer>
               </div>
            </div>
            </>
        )
    }
}
const mapStateToProps = state =>{
    return {
        token : state.token,
    }
}
export default connect(mapStateToProps)(SearchPage);