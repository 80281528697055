import React from 'react';
import emptyBoxImage from '../../PublicImage/box.svg';
import styles from './EmptyBox.module.css';
const emptyBox = (props)=>{
    return (
        <div className={styles.EmptyBox}>
                <img src = {emptyBoxImage} alt="Empty" />
                {/* <h1>ویدیوی ذخیره شده‌ای وجود ندارد!</h1> */}
                </div>
    )
}

export default emptyBox;