import React from 'react';
import LogoImg from './img/Logo.svg';
import styles from './Logo.module.css';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/action';
const logo = (props)=> {
    return (
        <Link 
        className={[styles.Link , props.sideDrawerOpen ? styles.Hide : null].join(' ')} 
        to="/Home" 
        onClick={()=>{
            if (props.onCloseSideDrawer) {
                props.onCloseSideDrawer();
            }
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            
            }}>
        {/* <span className={styles.Academy}> academy </span> */}
        <img className={[styles.Logo, props.white ? styles.White : null].join(' ')} src={LogoImg} alt="bracket-academy-logo" />
        </Link>
    )
}
const mapDispatchToProps = dispatch => {
    return {
        onCloseSideDrawer : ()=> dispatch(actions.CloseSideDrawer()),
    }
}
export default connect(null , mapDispatchToProps)(logo);