import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import styles from './Slider.module.css';
import React  from 'react';
// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/effect-fade/effect-fade.min.css';
import Slide from './Slide/Slide';

import { Link } from 'react-router-dom';

const Slider = (props)=> {
 
  let slides = null;
  if (props.slides) {
    if (props.slides.length > 0) {
      slides =  props.slides.map((slide)=>{
        return <SwiperSlide
        key = {slide.id} 
        className={styles.SwiperSlide}>
          <Slide
          Parts = {slide.parts}
          Time = {slide.time} 
          Image={slide.image} 
          Name={slide.name}
          Path={props.PathPrefix + slide.path} />
          </SwiperSlide> })
        
    }
    else {
      slides = <SwiperSlide className={styles.SwiperSlide}>
        <span className={styles.NoDataFallback}>اطلاعاتی برای نمایش وجود ندارد</span>
        </SwiperSlide>
    }
  }
    SwiperCore.use([Navigation]);
  return (
      <div className={styles.Slider}>
          <div className={styles.TextContainer}>
          <h1 className={styles.Title}>{props.title}</h1>
          {props.MoreButton ? <div className={styles.MoreButton}>
          <Link className={styles.Link}>همه</Link>
          <div className={styles.Arrow}>
                <div className={styles.RightArrow}></div>
                <div className={styles.LeftArrow}></div>
            </div>
          </div> : null}
          </div>
        <div className={styles.Swiper}>
    {slides ? <Swiper className={styles.SwiperSlider}
      autoHeight='true'
      navigation
      onSlideChange={() => {}}
      onSwiper={(swiper) => {}}
      breakpoints={{
        400: {
          slidesPerView: 1,
          spaceBetween: 32
         
        },
        // when window width is >= 480px
        550: {
          slidesPerView: 2,
          spaceBetween: 32
          
        },
        750: {
          slidesPerView: 3,
          spaceBetween: 32
          
        },
        1100: {
          slidesPerView: 6,
          spaceBetween: 32
        }
      }}
        
    >

      {slides}
      
    </Swiper> : 
    <span>در حال دریافت اطلاعات ...</span>}
    </div>
    </div>
  );
};
export default Slider;