import React from 'react';
import { connect } from 'react-redux';
import styles from './Avatar.module.css';
import AvatarPNG from './Avatar.png';
const avatar = (props) => {
    let src = '';
    if(props.userData) {
        if(props.userData.avatar)
        {
            src = props.userData.avatar;
        }
        else {
            src = AvatarPNG;
        }
    }
    return (
        <img
        src={src}
        className={styles.Avatar} 
        alt={props.avatarAlt} />
    )
}
const mapStateToProps = state =>{
    return {
        userData : state.userData,
        

    }
}
export default connect(mapStateToProps)(avatar);